import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import {  } from '../actions/api'
import { clearSelectedToken, clearTokenEnquire, changeSearchState } from '../actions/localStates'
import { formatDate } from '../actions/helpers'

import ActiveKeysView from './ActiveKeysView'
import WaitingKeysView from './WaitingKeysView'
import OtherKeysView from './OtherKeysView'
import OtherCertificatesView from './OtherCertificatesView'
import OtherCertificatesRequestsView from './OtherCertificatesRequestsView'

class TokenView extends Component {

	constructor(props) {
	    super(props);
	    this.state = {

	    }
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	handleReturn () {
		this.props.actions.clearTokenEnquire()
		this.props.actions.clearSelectedToken()
		this.props.actions.changeSearchState(false);
	}

	render() {
		return (
			<div className="token-container">
				<div className="return-div">
					<button className="btn btn-outline-info" style={{marginTop: "10px", marginLeft: "10px"}} onClick={this.handleReturn.bind(this)}>
						<i className="fa fa-arrow-circle-left" style={{color: "#1C92C7", fontSize: "1.2em"}}></i>
						&nbsp;<span style={{fontSize: "18px"}}>{i18next.t("returnLabel")}</span>
					</button>
				</div>
				
				<div className="token-view">
					<div style={{paddingLeft: "10px", paddingRight: "10px"}}>
					{
						this.props.tokenList.list[this.props.tokenList.selectedToken]
						? <div>
							<div style={{paddingLeft: "0px", paddingRight: "0px", marginTop: "10px"}} className={this.props.tokenList.list[this.props.tokenList.selectedToken].status === "BLOCKED" ? "table-border-blocked row col-lg-12 col-md-12 col-sm-12 col-xs-12" : "table-border row col-lg-12 col-md-12 col-sm-12 col-xs-12"}>
								<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-3">
									<div className="table-header">
										<label>{i18next.t("tokenName")}</label>
									</div>
									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<label>{this.props.tokenList.list[this.props.tokenList.selectedToken].title}</label>
									</div>
								</div>
								<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-3">
									<div className="table-header">
										<label>{i18next.t("tokenUri")}</label>
									</div>
									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<label>
											{
												this.props.tokenList.list[this.props.tokenList.selectedToken].uri
											}
										</label>
									</div>
								</div>
								<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-3">
									<div className="table-header">
										<label>{i18next.t("tokenStatus")}</label>
									</div>
									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<label>
												{i18next.t(this.props.tokenList.list[this.props.tokenList.selectedToken].status)}
										</label>
									</div>
								</div>
								<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-3">
									<div className="table-header">
										<label>{i18next.t("tokenChangedDate")}</label>
									</div>
									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										{
											<label>
												{
													this.props.actions.formatDate(this.props.tokenList.list[this.props.tokenList.selectedToken].statusChangeDateTime)
												}
											</label>
										}
									</div>
								</div>
								<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-1 col-md-1 col-sm-12 col-xs-12">
									<div className="table-header min-heigth-33">
										<label></label>
									</div>
								</div>
							</div>
						</div>
						: null
					}

	                <ActiveKeysView />
	                <WaitingKeysView />
	                <OtherKeysView />
	                <OtherCertificatesView />
	                <OtherCertificatesRequestsView />
	                
				</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        tokenList: state.tokenListReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	clearSelectedToken, formatDate, clearTokenEnquire,
    	changeSearchState
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenView);