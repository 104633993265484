import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uk from 'date-fns/locale/uk';
import { formatDateForCalendar } from '../actions/helpers'

import { setTokenInTrashSearchUri, setTokenInTrashSearchOperator, setTokenInTrashSearchTrashInDateNotBefore, setTokenInTrashSearchTrashInDateNotAfter, setTokenInTrashSearchTrashOutDateNotBefore,
			setTokenInTrashSearchTrashOutDateNotAfter, setTokenInTrashSearchOwnerId, setTokenInTrashSearchOwnerCommonName, setTokenInTrashSearchOwnerDrfou, setTokenInTrashSearchOwnerEdrpou,
			setTokenInTrashSearchOwnerOperator, setTokenInTrashSearchOwnerParams, clearAllTokenInTrashSearchFields } from '../actions/localStates'

import i18next from 'i18next'

import calenderIcon from '../img/calendar.svg'

registerLocale('uk', uk)
setDefaultLocale('uk')

class TokenInTrashSearchView extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	trashInDateTimeNotBefore: this.props.actions.formatDateForCalendar(this.props.search.trashInDateTimeNotBefore) || "",
	    	trashInDateTimeNotAfter: this.props.actions.formatDateForCalendar(this.props.search.trashInDateTimeNotAfter) || "",
	    	trashOutDateTimeNotBefore: this.props.actions.formatDateForCalendar(this.props.search.trashOutDateTimeNotBefore) || "",
	    	trashOutDateTimeNotAfter: this.props.actions.formatDateForCalendar(this.props.search.trashOutDateTimeNotAfter) || ""
	    }

	    this.trashInDateTimeNotBefore = React.createRef();
	    this.trashInDateTimeNotAfter = React.createRef();

	    this.trashOutDateTimeNotBefore = React.createRef();
	    this.trashOutDateTimeNotAfter = React.createRef();

	    this.onUriChanged = this.onUriChanged.bind(this);
	    this.onOperatorChanged = this.onOperatorChanged.bind(this);

	    this.onOwnerParamsChanged = this.onOwnerParamsChanged.bind(this);

	    this.onTrashInDateTimeNotBeforeChanged = this.onTrashInDateTimeNotBeforeChanged.bind(this);
		this.onTrashInDateTimeNotAfterChanged = this.onTrashInDateTimeNotAfterChanged.bind(this);
		this.onTrashOutDateTimeNotBeforeChanged = this.onTrashOutDateTimeNotBeforeChanged.bind(this);
		this.onTrashOutDateTimeNotAfterChanged = this.onTrashOutDateTimeNotAfterChanged.bind(this);

		this.clearForm = this.clearForm.bind(this);

		this.handleSearchKeyUp = this.keyUpHandler.bind(this, 'Search');

	}

	onUriChanged (e) {
		this.props.actions.setTokenInTrashSearchUri(e.target.value);
	}

	onOperatorChanged (e) {
		this.props.actions.setTokenInTrashSearchOperator(e.target.value);
	}

	onOwnerParamsChanged (e) {
    	if (e.target.value.length > 0) {
			this.props.actions.setTokenInTrashSearchOwnerParams(e.target.value);
		} else {
			this.props.actions.setTokenInTrashSearchOwnerParams(null);
		}
	}

	onTrashInDateTimeNotBeforeChanged (d) {
        this.props.actions.setTokenInTrashSearchTrashInDateNotBefore(d)
        this.setState({trashInDateTimeNotBefore: d})
    }

    onTrashInDateTimeNotAfterChanged (d) {
        this.props.actions.setTokenInTrashSearchTrashInDateNotAfter(d)
        this.setState({trashInDateTimeNotAfter: d})
    }

    onTrashOutDateTimeNotBeforeChanged (d) {
        this.props.actions.setTokenInTrashSearchTrashOutDateNotBefore(d)
        this.setState({trashOutDateTimeNotBefore: d})
    }

    onTrashOutDateTimeNotAfterChanged (d) {
        this.props.actions.setTokenInTrashSearchTrashOutDateNotAfter(d)
        this.setState({trashOutDateTimeNotAfter: d})
    }

	keyUpHandler(refName, e) {
        if (e.keyCode === 13) {
    		document.getElementById("search").click();
       		document.activeElement.blur()
        }
    }

	clearForm () {
		this.props.actions.clearAllTokenInTrashSearchFields();
		this.props.actions.setTokenInTrashSearchOwnerParams(null);
		this.setState({trashInDateTimeNotBefore: ""});
		this.setState({trashInDateTimeNotAfter: ""});
		this.setState({trashOutDateTimeNotBefore: ""});
		this.setState({trashOutDateTimeNotAfter: ""});
		
		this.props.refresh();
	}

	componentDidMount() {

	}

	render() {
		return (
			<div>
				<div className="searh-bar-max-height" style={{marginBottom: "10px"}}>
					<div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{padding: "0px"}}>
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("tokenUri")}</label>
							<input className="form-control" type="text" value={this.props.search.uri !== null ? this.props.search.uri : ""} onChange={this.onUriChanged} onKeyUp={this.handleSearchKeyUp} ref="Search" />
						</div>
						{/*<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 padding-left">
							<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("operator")}</label>
							<input className="form-control" type="text" value={this.props.search.operator !== null ? this.props.search.operator : ""} onChange={this.onOperatorChanged} onKeyUp={this.handleSearchKeyUp} ref="Search" />
						</div>*/}
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("ownerData")}</label>
							<input className="form-control" placeholder={i18next.t("ownerConfidantPlaceholder")} type="text" value={this.props.search.ownerParams !== null ? this.props.search.ownerParams : ""} onChange={this.onOwnerParamsChanged} onKeyUp={this.handleSearchKeyUp} ref="Search" />
						</div>
						<div className="row col-lg-3 col-md-6 col-sm-6 col-xs-12" style={{padding: "0px"}}>
		                    <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("trashInDateTimeNotBefore")}</label>
		                    <div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding">
		                        
		                        <div className="col-10 no-left-right-margin">
		                            <div className="customDatePickerWidth">
		                            <DatePicker ref={this.trashInDateTimeNotBefore} dateFormat="dd.MM.yyyy" maxDate={new Date()} className="form-control pointer" onClick={() => {this.trashInDateTimeNotBefore.current.setOpen(true)}}
		                                selected={this.state.trashInDateTimeNotBefore}
		                                onChange={this.onTrashInDateTimeNotBeforeChanged} />
		                            </div>
		                        </div>
		                        <div className="col-2 no-left-right-margin">
		                            <img className="img" onClick={() => {this.trashInDateTimeNotBefore.current.setOpen(true)}} style={{cursor: "pointer"}}width="50px" height="40px" src={calenderIcon} alt="calenderIcon" />
		                        </div>

		                    </div>
		                </div>
		                <div className="row col-lg-3 col-md-6 col-sm-6 col-xs-12" style={{padding: "0px"}}>
		                    <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("trashInDateTimeNotAfter")}</label>
		                    <div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding">
		                        
		                        <div className="col-10 no-left-right-margin">
		                            <div className="customDatePickerWidth">
		                            <DatePicker ref={this.trashInDateTimeNotAfter} dateFormat="dd.MM.yyyy" maxDate={new Date()} className="form-control pointer" onClick={() => {this.trashInDateTimeNotAfter.current.setOpen(true)}}
		                                selected={this.state.trashInDateTimeNotAfter}
		                                onChange={this.onTrashInDateTimeNotAfterChanged} />
		                            </div>
		                        </div>
		                        <div className="col-2 no-left-right-margin">
		                            <img className="img" onClick={() => {this.trashInDateTimeNotAfter.current.setOpen(true)}} style={{cursor: "pointer"}}width="50px" height="40px" src={calenderIcon} alt="calenderIcon" />
		                        </div>

		                    </div>
		                </div>
		                <div className="row col-lg-3 col-md-6 col-sm-6 col-xs-12" style={{padding: "0px"}}>
		                    <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("trashOutDateTimeNotBefore")}</label>
		                    <div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding">
		                        
		                        <div className="col-10 no-left-right-margin">
		                            <div className="customDatePickerWidth">
		                            <DatePicker ref={this.trashOutDateTimeNotBefore} dateFormat="dd.MM.yyyy" maxDate={new Date()} className="form-control pointer" onClick={() => {this.trashOutDateTimeNotBefore.current.setOpen(true)}}
		                                selected={this.state.trashOutDateTimeNotBefore}
		                                onChange={this.onTrashOutDateTimeNotBeforeChanged} />
		                            </div>
		                        </div>
		                        <div className="col-2 no-left-right-margin">
		                            <img className="img" onClick={() => {this.trashOutDateTimeNotBefore.current.setOpen(true)}} style={{cursor: "pointer"}}width="50px" height="40px" src={calenderIcon} alt="calenderIcon" />
		                        </div>

		                    </div>
		                </div>
		                <div className="row col-lg-3 col-md-6 col-sm-6 col-xs-12" style={{padding: "0px"}}>
		                    <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("trashOutDateTimeNotAfter")}</label>
		                    <div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding">
		                        
		                        <div className="col-10 no-left-right-margin">
		                            <div className="customDatePickerWidth">
		                            <DatePicker ref={this.trashOutDateTimeNotAfter} dateFormat="dd.MM.yyyy" className="form-control pointer" onClick={() => {this.trashOutDateTimeNotAfter.current.setOpen(true)}}
		                                selected={this.state.trashOutDateTimeNotAfter}
		                                onChange={this.onTrashOutDateTimeNotAfterChanged} />
		                            </div>
		                        </div>
		                        <div className="col-2 no-left-right-margin">
		                            <img className="img" onClick={() => {this.trashOutDateTimeNotAfter.current.setOpen(true)}} style={{cursor: "pointer"}}width="50px" height="40px" src={calenderIcon} alt="calenderIcon" />
		                        </div>

		                    </div>
		                </div>

					</div>
					<div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{padding: "0px"}}>
						
					</div>
				</div>
				<button className="btn btn-outline-info" style={{marginTop: "10xp", marginLeft: "10px", marginRight: "15px", marginBottom: "10px", float: "right"}} onClick={this.clearForm}>{i18next.t("cleanUpLabel")}</button>
				<button id="search" className="btn btn-info" style={{marginTop: "10xp", marginLeft: "10px", marginRight: "0px", marginBottom: "10px", float: "right"}} onClick={this.props.searchToken}>{i18next.t("searchLabel")}</button>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        search: state.tokenInTrashSearchReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    formatDateForCalendar, setTokenInTrashSearchUri,
		setTokenInTrashSearchOperator,
		setTokenInTrashSearchTrashInDateNotBefore,
		setTokenInTrashSearchTrashInDateNotAfter,
		setTokenInTrashSearchTrashOutDateNotBefore,
		setTokenInTrashSearchTrashOutDateNotAfter,
		setTokenInTrashSearchOwnerId,
		setTokenInTrashSearchOwnerCommonName,
		setTokenInTrashSearchOwnerDrfou,
		setTokenInTrashSearchOwnerEdrpou,
		setTokenInTrashSearchOwnerOperator,
		setTokenInTrashSearchOwnerParams,
		clearAllTokenInTrashSearchFields
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenInTrashSearchView);