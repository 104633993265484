import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uk from 'date-fns/locale/uk';

import { setTokenSearchUri, setTokenSearchTitle, setTokenSearchStatus, setTokenSearchDateNotBefore, setTokenSearchDateNotAfter, setTokenSearchOwnerId, setTokenSearchOwnerCommonName,
			setTokenSearchOwnerDrfou, setTokenSearchOwnerEdrpou, setTokenSearchConfidantId, setTokenSearchConfidantCommonName, setTokenSearchConfidantDrfou, setTokenSearchConfidantEdrpou,
			clearAllTokenSearchFields, setTokenSearchOwnerParams, setTokenSearchConfidantParams } from '../actions/localStates'
import { formatDateForCalendar } from '../actions/helpers'

import i18next from 'i18next'

import calenderIcon from '../img/calendar.svg'

registerLocale('uk', uk)
setDefaultLocale('uk')


class TokenSearchView extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	trashInDateTimeNotBefore: this.props.actions.formatDateForCalendar(this.props.search.trashInDateTimeNotBefore) || "",
	    	trashInDateTimeNotAfter: this.props.actions.formatDateForCalendar(this.props.search.trashInDateTimeNotAfter) || "",
	    	trashOutDateTimeNotBefore: this.props.actions.formatDateForCalendar(this.props.search.trashOutDateTimeNotBefore) || "",
	    	trashOutDateTimeNotAfter: this.props.actions.formatDateForCalendar(this.props.search.trashOutDateTimeNotAfter) || ""

	    }

	    this.trashInDateTimeNotBefore = React.createRef();
	    this.trashInDateTimeNotAfter = React.createRef();
	    this.trashOutDateTimeNotBefore = React.createRef();
	    this.trashOutDateTimeNotAfter = React.createRef();

	    this.onUriChanged = this.onUriChanged.bind(this);
	    this.onTitleChanged = this.onTitleChanged.bind(this);
	    this.onStatusChanged = this.onStatusChanged.bind(this);

	    this.onOwnerParamsChanged = this.onOwnerParamsChanged.bind(this);
	    this.onConfidantParamsChanged = this.onConfidantParamsChanged.bind(this);

	    this.onDateFromChanged = this.onDateFromChanged.bind(this);
		this.onDateToChanged = this.onDateToChanged.bind(this);

		this.clearForm = this.clearForm.bind(this);

		this.handleSearchKeyUp = this.keyUpHandler.bind(this, 'Search');

	}

	onUriChanged (e) {
		this.props.actions.setTokenSearchUri(e.target.value);
	}

	onTitleChanged (e) {
		this.props.actions.setTokenSearchTitle(e.target.value);
	}

	onStatusChanged (e) {
		this.props.actions.setTokenSearchStatus(e.target.value);
	}

	onDateFromChanged (d) {
        this.props.actions.setTokenSearchDateNotBefore(d)
        this.setState({createdDateTimeNotBefore: d})
    }

    onDateToChanged (d) {
        this.props.actions.setTokenSearchDateNotAfter(d)
        this.setState({createdDateTimeNotAfter: d})
    }

    onOwnerParamsChanged (e) {
    	if (e.target.value.length > 0) {
			this.props.actions.setTokenSearchOwnerParams(e.target.value);
		} else {
			this.props.actions.setTokenSearchOwnerParams(null);
		}
	}

	onConfidantParamsChanged (e) {
		if (e.target.value.length > 0) {
			this.props.actions.setTokenSearchConfidantParams(e.target.value);
		} else {
			this.props.actions.setTokenSearchConfidantParams(null);
		}
	}

	keyUpHandler(refName, e) {
        if (e.keyCode === 13) {
    		document.getElementById("search").click();
       		document.activeElement.blur()
        }
    }

	clearForm () {
		this.props.actions.clearAllTokenSearchFields();
		this.setState({createdDateTimeNotBefore: ""});
		this.setState({createdDateTimeNotAfter: ""});
		
		this.props.refresh();
	}

	componentDidMount() {
		
	}

	render() {
		return (
			<div>
				<div className="searh-bar-max-height" style={{marginBottom: "10px"}}>
					<div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{padding: "0px"}}>
						<div className="col-lg-5 col-md-6 col-sm-6 col-xs-12">
							<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("tokenUri")}</label>
							<input className="form-control" type="text" value={this.props.search.uri !== null ? this.props.search.uri : ""} onChange={this.onUriChanged} onKeyUp={this.handleSearchKeyUp} ref="Search" />
						</div>
						<div className="col-lg-5 col-md-6 col-sm-6 col-xs-12 padding-left">
							<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("tokenName")}</label>
							<input className="form-control" type="text" value={this.props.search.title !== null ? this.props.search.title : ""} onChange={this.onTitleChanged} onKeyUp={this.handleSearchKeyUp} ref="Search" />
						</div>
						<div className="col-lg-2 col-md-4 col-sm-4 col-xs-12 padding-left-sm">
							<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("tokenStatus")}</label>
							<select type="select" className="form-control" defaultValue={this.props.search.status !== null ? this.props.search.status : "ACTIVE"} onChange={this.onStatusChanged} onKeyUp={this.handleSearchKeyUp} ref="Search">
						        <option key="0" value="ACTIVE" selected={this.props.search.status === "ACTIVE" ? true : false}>{i18next.t("ACTIVE")}</option>
						        <option key="1" value="BLOCKED" selected={this.props.search.status === "BLOCKED" ? true : false}>{i18next.t("BLOCKED")}</option>
						    </select>
						</div>
						<div className="row col-lg-3 col-md-4 col-sm-4 col-xs-12" style={{padding: "0px"}}>
		                    <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("dateFrom")}</label>
		                    <div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding">
		                        
		                        <div className="col-10 no-left-right-margin">
		                            <div className="customDatePickerWidth">
		                            <DatePicker ref={this.calendarFromRef} dateFormat="dd.MM.yyyy" maxDate={new Date()} className="form-control pointer" onClick={() => {this.calendarFromRef.current.setOpen(true)}}
		                                selected={this.state.createdDateTimeNotBefore}
		                                onChange={this.onDateFromChanged} />
		                            </div>
		                        </div>
		                        <div className="col-2 no-left-right-margin">
		                            <img className="img" onClick={() => {this.calendarFromRef.current.setOpen(true)}} style={{cursor: "pointer"}}width="50px" height="40px" src={calenderIcon} alt="calenderIcon" />
		                        </div>

		                    </div>
		                </div>
		                <div className="row col-lg-3 col-md-4 col-sm-4 col-xs-12" style={{padding: "0px"}}>
		                    <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px"}}>{i18next.t("dateTo")}</label>
		                    <div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12 no-right-padding">
		                        
		                        <div className="col-10 no-left-right-margin">
		                            <div className="customDatePickerWidth">
		                            <DatePicker ref={this.calendarToRef} dateFormat="dd.MM.yyyy" maxDate={new Date()} className="form-control pointer" onClick={() => {this.calendarToRef.current.setOpen(true)}}
		                                selected={this.state.createdDateTimeNotAfter}
		                                onChange={this.onDateToChanged} />
		                            </div>
		                        </div>
		                        <div className="col-2 no-left-right-margin">
		                            <img className="img" onClick={() => {this.calendarToRef.current.setOpen(true)}} style={{cursor: "pointer"}}width="50px" height="40px" src={calenderIcon} alt="calenderIcon" />
		                        </div>

		                    </div>
		                </div>
		                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
							<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("ownerData")}</label>
							<input className="form-control" placeholder={i18next.t("ownerConfidantPlaceholder")} type="text" value={this.props.search.ownerParams !== null ? this.props.search.ownerParams : ""} onChange={this.onOwnerParamsChanged} onKeyUp={this.handleSearchKeyUp} ref="Search" />
						</div>
						<div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
							<label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingBottom: "0px", paddingLeft: "0px"}}>{i18next.t("confidantData")}</label>
							<input className="form-control" placeholder={i18next.t("ownerConfidantPlaceholder")} type="text" value={this.props.search.confidantParams !== null ? this.props.search.confidantParams : ""} onChange={this.onConfidantParamsChanged} onKeyUp={this.handleSearchKeyUp} ref="Search" />
						</div>
					</div>
					<div className="row col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{padding: "0px"}}>
						
					</div>
				</div>
				<button className="btn btn-outline-info" style={{marginTop: "10xp", marginLeft: "10px", marginRight: "15px", marginBottom: "10px", float: "right"}} onClick={this.clearForm}>{i18next.t("cleanUpLabel")}</button>
				<button id="search" className="btn btn-info" style={{marginTop: "10xp", marginLeft: "10px", marginRight: "0px", marginBottom: "10px", float: "right"}} onClick={this.props.searchToken}>{i18next.t("searchLabel")}</button>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        search: state.tokenSearchReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	formatDateForCalendar, setTokenSearchUri, setTokenSearchTitle, setTokenSearchStatus, setTokenSearchDateNotBefore,
    	setTokenSearchDateNotAfter, setTokenSearchOwnerId, setTokenSearchOwnerCommonName,
		setTokenSearchOwnerDrfou, setTokenSearchOwnerEdrpou, setTokenSearchConfidantId,
		setTokenSearchConfidantCommonName, setTokenSearchConfidantDrfou, setTokenSearchConfidantEdrpou,
		clearAllTokenSearchFields, setTokenSearchOwnerParams, setTokenSearchConfidantParams
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenSearchView);