import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import {  } from "../actions/api"
import {  } from "../actions/localStates"
import { formatDate } from '../actions/helpers'

import saveAs from 'file-saver';

import CertificateInfoPopup from './CertificateInfoPopup'

class ActiveKeysView extends Component {
	constructor() {
		super();
		this.state = {
			showAgreementActions: false,
			showSignatureActions: false,
			showCertificateInfo: false,
			showPopup: false,
			info: null,
			showConfirmPopup: false,
			activeHandler: null,
			actionInfo: "",
			active2fActiveKeysHandler: "",
			confirm2fTitle: "",
	    	numberOfPinInput: 0,
	    	needToReEnterPin: false,
	    	isActiveView: false,
	    	id: ""
		};

		this.handleShowAgreementActions = this.handleShowAgreementActions.bind(this);
	    this.handleCloseAgreementActions = this.handleCloseAgreementActions.bind(this);

	    this.handleShowSignatureActions = this.handleShowSignatureActions.bind(this);
	    this.handleCloseSignatureActions = this.handleCloseSignatureActions.bind(this);

	}

	handleShowAgreementActions (event) {
		event.preventDefault();
		this.setState({
				showAgreementActions: !this.state.showAgreementActions
			}, () => {
			document.addEventListener('click', this.handleCloseAgreementActions);
		});
	}

	handleCloseAgreementActions (event) { 
		this.setState({ showAgreementActions: false }, () => {
			document.removeEventListener('click', this.handleCloseAgreementActions);
		});  
	}

	handleShowSignatureActions (event) {
		event.preventDefault();
		this.setState({
				showSignatureActions: !this.state.showSignatureActions
			}, () => {
			document.addEventListener('click', this.handleCloseSignatureActions);
		});
	}

	handleCloseSignatureActions (event) { 
		this.setState({ showSignatureActions: false }, () => {
			document.removeEventListener('click', this.handleCloseSignatureActions);
		});  
	}

	handleDownloadCertificate (id) {
		const byteCharacters = atob(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificates[id]);
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);

		const blob = new Blob([byteArray], {type: "application/octet-stream"});
		var fileName = id + ".cer";
		saveAs(blob, fileName);
	}

	handleDownloadCertificateRequest (id) {
		const byteCharacters = atob(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests[id]);
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);

		const blob = new Blob([byteArray], {type: "application/octet-stream"});
		var fileName = id + ".cer";
		saveAs(blob, fileName);
	}

	componentDidMount() {

	}

	componentWillUnmount () {

	}

	closeInfoPopup () {
		this.setState({
	        showCertificateInfo: !this.state.showCertificateInfo
	    });
	}

	showInfo (data) {
		this.setState({info: this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[data]});
		console.log(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[data]);
		this.setState({showCertificateInfo: !this.state.showCertificateInfo});
	}

	render() {
		let showAgreementOpen = 'dropdown-menu dropdown-menu-right'
		if (this.state.showAgreementActions) {
			showAgreementOpen = 'dropdown-menu dropdown-menu-right show'
		}

		let showSignatureOpen = 'dropdown-menu dropdown-menu-right'
		if (this.state.showSignatureActions) {
			showSignatureOpen = 'dropdown-menu dropdown-menu-right show'
		}

		return (
			<div>
				{
					this.props.tokenList.list[this.props.tokenList.selectedToken] && this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached !== null
					? <div style={{marginTop: "10px"}}>
						{
							Object.keys(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys).length === 0 && this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.constructor === Object
							? null
							: <div>
								<label>{i18next.t("activeKeys")}</label>
								{
									this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT !== undefined
									? <div style={{paddingLeft: "0px", paddingRight: "0px", marginBottom: "10px"}} className="table-border bg-color-active row col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "left"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
											<div className="table-header">
												<label>{i18next.t("owner")}</label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<label>
													{
														this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT].subject.commonName
													}
												</label>
												<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
													{
														i18next.t("drfou")
													}
													{
														this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT].extensions.drfou
													}
												</label>
												<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
													{
														i18next.t("edrpou")
													}
													{
														this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT].extensions.edrpou
													}
												</label>
											</div>
										</div>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
											<div className="table-header">
												<label>{i18next.t("appointment")}</label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT] !== undefined && this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT].extensions.keyUsage.indexOf("KEY_AGREEMENT") > -1
															? i18next.t("KEY_AGREEMENT")
															: i18next.t("DIGITAL_SIGNATURE")
														}
													</label>
												</div>
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT] !== undefined && this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT].extensions.isElectronicStamp === true
															? i18next.t("electronicStamp")
															: null
														}
													</label>
												</div>
											</div>
										</div>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
											<div className="table-header">
												<label>{i18next.t("key")}</label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															i18next.t("algorithm")
														}
														{
															this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].subjectPublicKeyInfo.algorithm === "1.2.840.10045.2.1" ? i18next.t("ECDSA-256") : (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].subjectPublicKeyInfo.algorithm === "1.2.840.113549.1.1.1" ? i18next.t("RSA") : this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT].subjectPublicKeyInfo.algorithm)
														}
													</label>
												</div>
												<div>
													<label className="row col-12" style={{textAlign: "left", paddingLeft: "0px", paddingRight: "0px", marginBottom: "0px"}}>
														{
															i18next.t("identifire")
														}
													</label>
													<div style={{wordWrap: "break-word", fontSize: "12px", textAlign: "left"}}>
														{
															this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT
														}
													</div>
												</div>
											</div>
										</div>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
											<div className="table-header">
												<label>{i18next.t("certificate")}</label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															i18next.t("notBefore")
														}
														{
															this.props.actions.formatDate(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT].notBefore)
														}
													</label>
												</div>
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															i18next.t("notAfter")
														}
														{
															this.props.actions.formatDate(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT].notAfter)
														}
													</label>
												</div>
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															i18next.t("issuer")
														}
														{
															this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT].issuer.commonName
														}
													</label>
												</div>
											</div>
										</div>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-1 col-md-1 col-sm-12 col-xs-12">
											<div className="table-header min-heigth-33">
												<label></label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div className="dropdown" style={{marginTop: "5px"}}>
						                            <button type="button" onClick={this.handleShowAgreementActions} className="btn btn-cog dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showAgreementActions}>
														<span><i style={{color: "#1C92C7", fontSize: "2.5em"}} className="fa fa-cog"></i></span>
													</button>
						                            <div className={showAgreementOpen}>
						                                {
						                                	this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificates[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT]
						                                	? <span className="dropdown-item pointer" onClick={this.handleDownloadCertificate.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT)}>{i18next.t("downloadCertificate")}</span>
						                                	: null
						                                }
						                                {
						                                	this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateRequests[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT]
						                                	? <span className="dropdown-item pointer" onClick={this.handleDownloadCertificateRequest.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT)}>{i18next.t("downloadCertificateRequest")}</span>
						                                	: null
						                                }
						                                {
						                                	this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificates[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT]
						                                	? <span className="dropdown-item pointer" onClick={this.showInfo.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.AGREEMENT)}>{i18next.t("certificateInfo")}</span>
						                                	: null
						                                }
						                            </div>
						                        </div>
											</div>
										</div>
									</div>
									: null
								}
								{
									this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE !== undefined
									? <div style={{paddingLeft: "0px", paddingRight: "0px"}} className="table-border bg-color-active row col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "left"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
											<div className="table-header">
												<label>{i18next.t("owner")}</label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<label>
													{
														this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE].subject.commonName
													}
												</label>
												<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
													{
														i18next.t("drfou")
													}
													{
														this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE].extensions.drfou
													}
												</label>
												<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
													{
														i18next.t("edrpou")
													}
													{
														this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE].extensions.edrpou
													}
												</label>
											</div>
										</div>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
											<div className="table-header">
												<label>{i18next.t("appointment")}</label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
													{
														this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE] !== undefined && this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE].extensions.keyUsage.indexOf("DIGITAL_SIGNATURE") > -1
														? i18next.t("DIGITAL_SIGNATURE")
														: i18next.t("KEY_AGREEMENT")
													}
												</label>
												<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
													{
														this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE] !== undefined && this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE].extensions.isElectronicStamp === true
														? i18next.t("electronicStamp")
														: null
													}
												</label>
											</div>
										</div>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
											<div className="table-header">
												<label>{i18next.t("key")}</label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div>
													<div>
														<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
															{
																i18next.t("algorithm")
															}
															{
																this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].subjectPublicKeyInfo.algorithm === "1.2.840.10045.2.1" ? i18next.t("ECDSA-256") : (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].subjectPublicKeyInfo.algorithm === "1.2.840.113549.1.1.1" ? i18next.t("RSA") : this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE].subjectPublicKeyInfo.algorithm)
															}
														</label>
													</div>
													<div>
														<label className="row col-12" style={{textAlign: "left", paddingLeft: "0px", paddingRight: "0px", marginBottom: "0px"}}>
															{
																i18next.t("identifire")
															}
														</label>
														<div style={{wordWrap: "break-word", fontSize: "12px", textAlign: "left"}}>
															{
																this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE
															}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
											<div className="table-header">
												<label>{i18next.t("certificate")}</label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															i18next.t("notBefore")
														}
														{
															this.props.actions.formatDate(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE].notBefore)
														}
													</label>
												</div>
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															i18next.t("notAfter")
														}
														{
															this.props.actions.formatDate(this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE].notAfter)
														}
													</label>
												</div>
												<div>
													<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
														{
															i18next.t("issuer")
														}
														{
															this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateInfos[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE].issuer.commonName
														}
													</label>
												</div>
											</div>
										</div>
										<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-1 col-md-1 col-sm-12 col-xs-12">
											<div className="table-header min-heigth-33">
												<label></label>
											</div>
											<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
												<div className="dropdown" style={{marginTop: "5px"}}>
						                            <button type="button" onClick={this.handleShowSignatureActions} className="btn btn-cog dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showSignatureActions}>
														<span><i style={{color: "#1C92C7", fontSize: "2.5em"}} className="fa fa-cog"></i></span>
													</button>
						                            <div className={showSignatureOpen}>
						                                {
						                                	this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificates[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE]
						                                	? <span className="dropdown-item pointer" onClick={this.handleDownloadCertificate.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE)}>{i18next.t("downloadCertificate")}</span>
						                                	: null
						                                }
						                                {
						                                	this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificateRequests[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE]
						                                	? <span className="dropdown-item pointer" onClick={this.handleDownloadCertificateRequest.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE)}>{i18next.t("downloadCertificateRequest")}</span>
						                                	: null
						                                }
						                                {
						                                	this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.certificates[this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE]
						                                	? <span className="dropdown-item pointer" onClick={this.showInfo.bind(this, this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached.activeKeys.SIGNATURE)}>{i18next.t("certificateInfo")}</span>
						                                	: null
						                                }
						                            </div>
						                        </div>
											</div>
										</div>
									</div>
									: null
								}
							</div>
						}
						<div>
							{
								this.state.showCertificateInfo
								? <CertificateInfoPopup certInfo={this.state.info} closePopup={this.closeInfoPopup.bind(this)} />
								: null
							}
						</div>
					</div>
					: null
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        tokenList: state.tokenListReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	formatDate
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveKeysView);