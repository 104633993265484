import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setNeedReLogin, setNeedToLogout } from '../actions/localStates'

class SessionEndPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
          seconds: 0
      };

      this.timer = 0;
      this.countDown = this.countDown.bind(this);
      this.startTimer = this.startTimer.bind(this);

      this.cancel = this.cancel.bind(this);
      this.login = this.login.bind(this);
  }

  componentDidMount () {
    var currentTime = new Date().getTime() / 1000;
    
    if (currentTime > this.props.base.expireTime + this.props.base.waitingTime * 1000) {
      this.props.actions.setNeedToLogout(true);
      return;
    }

    this.setState({seconds: this.props.base.waitingTime})
    this.startTimer();
  }

  componentWillUnmount () {
    clearInterval(this.timer);
    this.timer = 0;
  }

  startTimer () {
    if (this.timer === 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  login () {
    this.props.actions.setNeedReLogin(true)
  }

  cancel () {
    this.props.actions.setNeedToLogout(true);
  }

  countDown() {
    let seconds = this.state.seconds - 1;
    this.setState({
      seconds: seconds,
    });

    if (seconds <= 0) {
      this.props.actions.setNeedToLogout(true);
    }
  }

  render() {
      return (
          <div className='popup'>
              <div className={"popup_inner_user_token_pin"}>
                  <div className="col-12" style={{marginTop: "30px"}}>
                      <label style={{marginLeft: "15px", marginRight: "15px"}}>{i18next.t("sessionEndTitle")}</label>
                      <div style={{float: "right", marginRight: "15px", paddingTop: "10px"}}>
                          <button className="btn btn-info" id="submitPin" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.login}>{i18next.t("continueLabel")}</button>
                          <button className="btn btn-outline-info" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.cancel}>{i18next.t("cancelLabel")}</button>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        selectedClient: state.clientReducer,
        login: state.loginReducer,
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      setNeedReLogin, setNeedToLogout
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionEndPopup);