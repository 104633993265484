import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { pageUrl } from '../actions/helpers';
import { getTokenList } from '../actions/api';
import { setTokenList, setALLPaginationParams, setTokenSearchOwnerDrfou, setTokenSearchOwnerEdrpou, setTokenSearchOwnerId, setTokenSearchOwnerCommonName, setTokenSearchConfidantDrfou,
            setTokenSearchConfidantEdrpou, setTokenSearchConfidantId, setTokenSearchConfidantCommonName, setTokenSearchOwnerOperator,
            setTokenSearchConfidantOperator } from '../actions/localStates';

import TokenSearchView from '../components/TokenSearchView'

class TokenSearchContainer extends Component {
    constructor(props) {
        super(props);

        this.search = this.search.bind(this);
        this.refresh = this.refresh.bind(this);
    }

	componentDidMount() {

	}

    search () {
        var ownerId: null, ownerCommonName: null, ownerDrfou: null, ownerEdrpou: null,
            confidantId: null, confidantCommonName: null, confidantDrfou: null, confidantEdrpou: null,
            ownerData = this.props.search.ownerParams, confidantData = this.props.search.confidantParams,
            ownerOperator = null, confidantOperator = null;
        const re = /^[0-9+-?]+$/;

        if (ownerData !== null && ownerData.length > 0 && re.test(ownerData)) {
            if (ownerData.length > 8) {
                // eslint-disable-next-line
                ownerDrfou = ownerData !== null ? "\^" + ownerData : null;
                ownerEdrpou = null;
                ownerId = null;
                ownerCommonName = null;
                ownerOperator = null;
                this.props.actions.setTokenSearchOwnerDrfou(ownerDrfou)
                this.props.actions.setTokenSearchOwnerEdrpou(null)
                this.props.actions.setTokenSearchOwnerId(null)
                this.props.actions.setTokenSearchOwnerCommonName(null)
                this.props.actions.setTokenSearchOwnerOperator(null)
            } else if (this.props.search.ownerParams.length === 8) {
                ownerDrfou = null;
                // eslint-disable-next-line
                ownerEdrpou = ownerData !== null ? "\^" + ownerData : null;
                ownerId = null;
                ownerCommonName = null;
                ownerOperator = null;
                this.props.actions.setTokenSearchOwnerDrfou(null)
                this.props.actions.setTokenSearchOwnerId(null)
                this.props.actions.setTokenSearchOwnerCommonName(null)
                this.props.actions.setTokenSearchOwnerEdrpou(ownerEdrpou)
                this.props.actions.setTokenSearchOwnerOperator(null)
            } else {
                // eslint-disable-next-line
                ownerDrfou = ownerData !== null ? "\^" + ownerData : null;
                // eslint-disable-next-line
                ownerEdrpou = ownerData !== null ? "\^" + ownerData : null;
                ownerId = null;
                ownerCommonName = null;
                ownerOperator = "OR";
                this.props.actions.setTokenSearchOwnerDrfou(null)
                this.props.actions.setTokenSearchOwnerId(null)
                this.props.actions.setTokenSearchOwnerDrfou(ownerDrfou)
                this.props.actions.setTokenSearchOwnerEdrpou(ownerEdrpou)
                this.props.actions.setTokenSearchOwnerOperator("OR")
            }
        } else {
            if (ownerData !== null && ownerData.length > 0) {
                ownerOperator = "OR";
                this.props.actions.setTokenSearchOwnerOperator("OR")
            }

            ownerId = (ownerData !== null && ownerData.substring(0, 1) === "~") ? ownerData.substring(1, ownerData.length) : ownerData;
            ownerCommonName = (ownerData !== null && ownerData.substring(0, 1) === "~") ? ownerData.substring(1, ownerData.length) : ownerData;
            ownerEdrpou = null;
            ownerDrfou = null;
            ownerOperator = ownerOperator !== null ? ownerOperator : null;
            this.props.actions.setTokenSearchOwnerEdrpou(null)
            this.props.actions.setTokenSearchOwnerDrfou(null)
            this.props.actions.setTokenSearchOwnerId(ownerId)
            this.props.actions.setTokenSearchOwnerCommonName(ownerCommonName)
        }

        if (confidantData !== null && confidantData.length > 0 && re.test(confidantData)) {
            if (confidantData.length > 8) {
                // eslint-disable-next-line
                confidantDrfou = confidantData !== null ? "\^" + confidantData : null;
                confidantEdrpou = null;
                confidantId = null;
                confidantCommonName = null;
                confidantOperator = null;
                this.props.actions.setTokenSearchConfidantDrfou(confidantDrfou)
                this.props.actions.setTokenSearchConfidantEdrpou(null)
                this.props.actions.setTokenSearchConfidantId(null)
                this.props.actions.setTokenSearchConfidantCommonName(null)
                this.props.actions.setTokenSearchConfidantOperator(null)
            } else if (this.props.search.confidantParams.length === 8) {
                confidantDrfou = null;
                // eslint-disable-next-line
                confidantEdrpou = confidantData !== null ? "\^" + confidantData : null;
                confidantId = null;
                confidantCommonName = null;
                confidantOperator = null;
                this.props.actions.setTokenSearchConfidantId(null)
                this.props.actions.setTokenSearchConfidantCommonName(null)
                this.props.actions.setTokenSearchConfidantDrfou(null)
                this.props.actions.setTokenSearchConfidantEdrpou(confidantEdrpou)
                this.props.actions.setTokenSearchConfidantOperator(null)
            } else {
                // eslint-disable-next-line
                confidantDrfou = confidantData !== null ? "\^" + confidantData : null;
                // eslint-disable-next-line
                confidantEdrpou = confidantData !== null ? "\^" + confidantData : null;
                confidantId = null;
                confidantCommonName = null;
                confidantOperator = "OR";
                this.props.actions.setTokenSearchConfidantId(null)
                this.props.actions.setTokenSearchConfidantCommonName(null)
                this.props.actions.setTokenSearchConfidantDrfou(confidantDrfou)
                this.props.actions.setTokenSearchConfidantEdrpou(confidantEdrpou)
                this.props.actions.setTokenSearchConfidantOperator("OR")
            }
        } else {
            if (confidantData !== null && confidantData.length > 0) {
                confidantOperator = "OR";
                this.props.actions.setTokenSearchConfidantOperator("OR")
            }

            confidantId = (confidantData !== null && confidantData.substring(0, 1) === "~") ? confidantData.substring(1, confidantData.length) : confidantData;
            confidantCommonName = (confidantData !== null && confidantData.substring(0, 1) === "~") ? confidantData.substring(1, confidantData.length) : confidantData;
            confidantEdrpou = null;
            confidantDrfou = null;
            confidantOperator = confidantOperator !== null ? confidantOperator : null;
            this.props.actions.setTokenSearchConfidantEdrpou(null)
            this.props.actions.setTokenSearchConfidantDrfou(null)
            this.props.actions.setTokenSearchConfidantId(confidantId)
            this.props.actions.setTokenSearchConfidantCommonName(confidantCommonName)
        }

        var searchParams = {
            "uri": this.props.search.uri,
            "title": this.props.search.title,
            "status": this.props.search.status,
            "owner": {
                "id": ownerId,
                "commonName": ownerCommonName,
                "drfou": ownerDrfou,
                "edrpou": ownerEdrpou,
                "operator": ownerOperator
            },
            "confidant": {
                "id": confidantId,
                "commonName": confidantCommonName,
                "drfou": confidantDrfou,
                "edrpou": confidantEdrpou,
                "operator": confidantOperator
            },
            "createdDateTimeNotBefore": this.props.search.createdDateTimeNotBefore,
            "createdDateTimeNotAfter": this.props.search.createdDateTimeNotAfter
        }

        var params = {
            page: this.props.page.number,
            size: this.props.page.size
        }

        var urlParams = this.props.actions.pageUrl(params)

        this.props.actions.getTokenList(this.props.base.serviceUrl, urlParams, searchParams, this.props.login.accessToken)
        .then((response) => {

            this.props.actions.setTokenList(response.content)
            this.props.actions.setALLPaginationParams(response.page)

            console.log(response)
        });
    }

    refresh () {
        var searchParams = {
            "uri": null,
            "title": null,
            "status": null,
            "owner": {
                "id": null,
                "commonName": null,
                "drfou": null,
                "edrpou": null,
                "operator": null
            },
            "confidant": {
                "id": null,
                "commonName": null,
                "drfou": null,
                "edrpou": null,
                "operator": null
            },
            "createdDateTimeNotBefore": null,
            "createdDateTimeNotAfter": null
        }

        this.props.actions.getTokenList(this.props.base.serviceUrl, "", searchParams, this.props.login.accessToken)
        .then((response) => {

            this.props.actions.setTokenList(response.content)
            this.props.actions.setALLPaginationParams(response.page)

            console.log(response)
        });
    }

	render() {
		return (
				<TokenSearchView searchToken={this.search} refresh={this.refresh} />
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        search: state.tokenSearchReducer,
        login: state.loginReducer,
        page: state.paginationReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
        pageUrl, getTokenList,
        setTokenList, setALLPaginationParams, setTokenSearchOwnerDrfou, setTokenSearchOwnerEdrpou,
        setTokenSearchOwnerId, setTokenSearchOwnerCommonName, setTokenSearchConfidantDrfou,
        setTokenSearchConfidantEdrpou, setTokenSearchConfidantId, setTokenSearchConfidantCommonName,
        setTokenSearchOwnerOperator, setTokenSearchConfidantOperator
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenSearchContainer);