import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from 'universal-cookie';

import i18next from 'i18next'

import { login, verifyToken, getUserProfile, getPublicKeyCurrentUtcDateTime, init2f, login2f, getTokenList } from "../actions/api"
import { setToken, setExpireInTime, setIsLoggedIn, setLoggedInUserData, setAllUserFields,
		setPublicKeyCurrentUtcDateTime, setErrorResponse, setTwoFactorEnabled, clearTwoFactorEnabled, clearTwoFactorCode,
		setPasswordInput, setUpdate2fSettingsGrant, setTokenList, setALLPaginationParams, setNeedReLogin, setShowSessionEndHandler } from "../actions/localStates"

import UserInfoPopup from '../components/UserInfoPopup'
import Body from '../components/Body';
import TokenView from '../components/TokenView'
import SessionEndPopup from '../components/SessionEndPopup'
import TrashContainer from './TrashContainer'

const cookies = new Cookies();

class BodyContainer extends Component {
	constructor() {
		super();
		this.state = {
		  seconds: 0,
		  settedTimeValue: false,
		  token: "",
		  showErrorPopup: false,
		  needPing: false
		};

		this.loginRef = React.createRef();

		this.timer = 0;
		this.startTimer = this.startTimer.bind(this);
		this.countDown = this.countDown.bind(this);
		this.setTime = this.setTime.bind(this);
		this.setTimeValue = this.setTimeValue.bind(this);

		this.login2f = this.login2f.bind(this);

		this.ping = this.ping.bind(this);
	}

	componentDidMount() {
		this.setTime();
		this.activityWatcher();
		this.props.actions.setShowSessionEndHandler(false);

		var searchParams = {
            "uri": this.props.search.uri,
            "title": this.props.search.title,
            "status": this.props.search.status,
            "owner": {
                "id": this.props.search.ownerId,
                "commonName": this.props.search.ownerCommonName,
                "drfou": this.props.search.ownerDrfou,
                "edrpou": this.props.search.ownerEdrpou,
                "operator": this.props.search.ownerOperator
            },
            "confidant": {
                "id": this.props.search.confidantId,
                "commonName": this.props.search.confidantCommonName,
                "drfou": this.props.search.confidantDrfou,
                "edrpou": this.props.search.confidantEdrpou,
                "operator": this.props.search.confidantOperator
            },
            "createdDateTimeNotBefore": this.props.search.createdDateTimeNotBefore,
            "createdDateTimeNotAfter": this.props.search.createdDateTimeNotAfter
        }

		var urlParams = "";

		this.props.actions.getTokenList(this.props.base.serviceUrl, urlParams, searchParams, this.props.login.accessToken)
		.then((response) => {

			if (response.contentType !== undefined && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
                this.props.actions.setErrorResponse(response.json)
				this.setState({showErrorPopup: true})
                return
            }

			this.props.actions.setTokenList(response.content)
			this.props.actions.setALLPaginationParams(response.page)

			console.log(response)

			if (response.length > 0) {
				// this.props.actions.setTokenEnquire(response[0]);
				this.props.actions.setSelectedToken(0)
			}
		});
	}

	activityWatcher () {
        var _this = this;
        var secondsSinceLastActivity = 0;

        var maxInactivity = (60 * this.props.base.timeToLogout);

        
        setInterval(function () {
            if (!_this.props.base.showSessionEndHandler) {
                secondsSinceLastActivity += 1;
                // console.log(secondsSinceLastActivity + ' seconds since the user was last active');

                if (secondsSinceLastActivity > maxInactivity) {
                    // console.log('User has been inactive for more than ' + maxInactivity + ' seconds');
                    _this.props.actions.setShowSessionEndHandler(true);
                }
            }
        }, 1000);
        

        function activity() {
            secondsSinceLastActivity = 0;
        }

        var activityEvents = [
            'mousedown', 'mousemove', 'keydown',
            'scroll', 'touchstart'
        ];

        activityEvents.forEach(function(eventName) {
            document.addEventListener(eventName, activity, true);
        });
    }

	componentWillUnmount () {
		this.setTimeValue()
	}

	showMessagePopup () {
    	this.setState({
          	showErrorPopup: !this.state.showErrorPopup
      	});
  	}

	setTimeValue () {
		this.setState({settedTimeValue: false})
		clearInterval(this.timer);
		this.timer = 0;
		this.setState({needPing: false})
	}

	setTime() {
		var _this = this;
		var timeToExpire = this.props.base.expires_in;
		this.setState({seconds: timeToExpire})
		this.setState({settedTimeValue: true})
		this.setState({needPing: true})

		window.setTimeout(function () {
			_this.ping();
		}, 60000)

		this.startTimer();
	}

	ping () {
		if (this.state.needPing) {
			window.setTimeout(function () {
				fetch("8.8.8.8", {
			      method: 'GET',
			      headers: {
			          'Content-Type': 'application/json'
			      },
			      dataType: "json"
			    })
			    .then((response) => {
			    	console.log(response)
			    })
			}, 60000)
		}
	}

	startTimer () {
		if (this.timer === 0) {
		  this.timer = setInterval(this.countDown, 1000);
		}
	}

	login () {
    	var userData, _this = this, user;
        var containsRole = false;
        var error = false;

        this.props.actions.setShowSessionEndHandler(false);
		this.props.actions.setNeedReLogin(false);

        var substring = "handyman";

        if (this.props.base.selectedAuthProvider.indexOf(substring) !== -1) {
            user = {
                "grant_type" : "password",
                "user" : this.props.login.login,
                "client_id" : this.props.base.clientId,
                "password" : this.props.login.password
            }
        } else {
            user = {
                "grant_type" : "password",
                "username" : this.props.login.login,
                "client_id" : this.props.base.clientId,
                "password" : this.props.login.password
            }
        }

        var data = {
            twoFactorCode: ""
        }

    	this.props.actions.login(this.props.base.selectedAuthProvider, user)
    	.then((response) => {
            if (response.code !== undefined) {
                alert(response.message);
                return
            }

            function parseJwt (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            };

            this.props.actions.setToken(response.access_token);
            userData = parseJwt(response.access_token);
            console.log(userData)

            if (userData.twof) {
                this.props.actions.init2f(this.props.base.authUrl, data, this.props.login.accessToken)
                .then((response) => {
                    console.log(response);
                })
                this.props.actions.setTwoFactorEnabled(true);
                this.props.actions.setIs2fEnabled(true);
                return;
            } else {
                if (!userData.twof) {
                    this.props.actions.login2f(this.props.base.authUrl, user, this.props.login.accessToken)
                    .then((response) => {
                        if (response.code !== undefined) {
                            this.props.actions.clearTwoFactorEnabled();
                            this.props.actions.clearTwoFactorCode();
                            this.props.actions.setPasswordInput("");
                            error = true;
                            alert(response.message);
                            return
                        }

                        function parseJwt (token) {
                            var base64Url = token.split('.')[1];
                            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                            }).join(''));

                            return JSON.parse(jsonPayload);
                        };
                        this.props.actions.setToken(response.access_token);
                        userData = parseJwt(response.access_token);
                    })
                    .then(() => {
                        if (!error) {
                            this.props.actions.verifyToken(this.props.base.authUrl, this.props.login.accessToken)
                            .then((response) => {
                                if (response.expires_in !== undefined) {
                                    this.props.actions.setExpireInTime(response.expires_in)
                                }
                                
                                userData.scope.forEach(function (role) {
                                    if (role === "VTMS_ADMINISTRATOR") {
                                        containsRole = true; 
                                    } else {
                                        return;
                                    }
                                });


                                if (containsRole) {
                                    this.props.actions.getUserProfile(this.props.base.authUrl, this.props.login.accessToken)
                                    .then((response) => {
                                        _this.props.actions.setLoggedInUserData(response);
                                        _this.props.actions.setAllUserFields(response);

                                        _this.props.actions.getPublicKeyCurrentUtcDateTime(this.props.base.serviceUrl, this.props.login.accessToken)
                                        .then((response) => {
                                            _this.props.actions.setPublicKeyCurrentUtcDateTime(response);
                                            _this.props.actions.setIsLoggedIn(true);
                                        })
                                    })
                                } else {
                                    alert(i18next.t("premissionDenied"));
                                }
                            })
                        }
                    })
                }
            }
        })
    }

	login2f () {
        var userData, _this = this;
        var containsRole = false;
        var error = false;

        var code = {
            "twoFactorCode": this.props.login.twoFactorCode
        }
        
        
        this.props.actions.login2f(this.props.base.authUrl, code, this.props.login.accessToken)
        .then((response) => {
            if (response.code !== undefined) {
                this.props.actions.clearTwoFactorEnabled();
                this.props.actions.clearTwoFactorCode();
                this.props.actions.setPasswordInput("");
                this.props.actions.setNeed2fConfirm(false);
                error = true;
                this.props.actions.setIsLoggedIn(false);
                alert(response.message);
                return
            }

            function parseJwt (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            };
            this.props.actions.setToken(response.access_token);
            this.props.actions.setNeed2fConfirm(true);
            userData = parseJwt(response.access_token);
        })
        .then(() => {
            if (!error) {
                this.props.actions.verifyToken(this.props.base.authUrl, this.props.login.accessToken)
                .then((response) => {
                    if (response.expires_in !== undefined) {
                        this.props.actions.setExpireInTime(response.expires_in)
                    }

                    userData.scope.forEach(function (role) {
                    	// if (role === "UPDATE_2F_SETTINGS_GRANT") {
                     //        _this.props.actions.setUpdate2fSettingsGrant(true)
                     //    }

                     //    if (role === "CERTIFICATE_ISSUING_GRANT") {
                     //        _this.props.actions.setCertificateIssuingGrant(true)
                     //    }

                        if (role === "VTMS_ADMINISTRATOR") {
                            containsRole = true; 
                        } else {
                            return;
                        }
                    });


                    if (containsRole) {
                        this.props.actions.getUserProfile(this.props.base.authUrl, this.props.login.accessToken)
                        .then((response) => {
                            _this.props.actions.setLoggedInUserData(response);
                            _this.props.actions.setAllUserFields(response);

                            _this.props.actions.getPublicKeyCurrentUtcDateTime(this.props.base.serviceUrl, this.props.login.accessToken)
                            .then((response) => {
                            	_this.props.actions.setTwoFactorEnabled(false);
                				_this.props.actions.clearTwoFactorCode();
                                _this.props.actions.setPublicKeyCurrentUtcDateTime(response);
                                _this.props.actions.setIsLoggedIn(true);
                                _this.setTime();
                            })
                        })
                    } else {
                        alert(i18next.t("premissionDenied"));
                    }
                })
            }
        })
    }

	countDown() {
		var userData, _this = this, user;
        var containsRole = false;
		var error = false;
		
		var substring = "handyman";

        if (this.props.base.selectedAuthProvider.indexOf(substring) !== -1) {
            user = {
                "grant_type" : "password",
                "user" : this.props.login.login,
                "client_id" : this.props.base.clientId,
                "password" : this.props.login.password
            }
        } else {
            user = {
                "grant_type" : "password",
                "username" : this.props.login.login,
                "client_id" : this.props.base.clientId,
                "password" : this.props.login.password
            }
        }

        var data = {
            twoFactorCode: ""
        }

		let seconds = this.state.seconds - 1;
		this.setState({
		  seconds: seconds,
		});

		cookies.set('timestamp', Date.now());

		if (seconds <= 0) {
			clearInterval(this.timer);
			this.setTimeValue();
			this.props.actions.setShowSessionEndHandler(true);
			this.props.actions.login(this.props.base.selectedAuthProvider, user)
	    	.then((response) => {
	            if (response.code !== undefined) {
	                error = true;
	                alert(response.message);
	                return
	            }
	            function parseJwt (token) {
	                var base64Url = token.split('.')[1];
	                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
	                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	                }).join(''));

	                return JSON.parse(jsonPayload);
	            };
	            this.props.actions.setToken(response.access_token);
	            userData = parseJwt(response.access_token);

	            if (userData.twof) {
	                this.props.actions.init2f(this.props.base.authUrl, data, this.props.login.accessToken)
	                .then((response) => {
	                    console.log(response);
	                })
	                this.props.actions.setTwoFactorEnabled(true);
	                return;
	            } else {
	                if (!userData.twof) {
	                    this.props.actions.login2f(this.props.base.authUrl, user, this.props.login.accessToken)
	                    .then((response) => {
	                        if (response.code !== undefined) {
	                            this.props.actions.clearTwoFactorEnabled();
	                            this.props.actions.clearTwoFactorCode();
	                            this.props.actions.setPasswordInput("");
	                            error = true;
	                            alert(response.message);
	                            return
	                        }

	                        function parseJwt (token) {
	                            var base64Url = token.split('.')[1];
	                            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	                            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
	                                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	                            }).join(''));

	                            return JSON.parse(jsonPayload);
	                        };
	                        this.props.actions.setToken(response.access_token);
	                        userData = parseJwt(response.access_token);
	                    })
	                    .then(() => {
	                        if (!error) {
	                            this.props.actions.verifyToken(this.props.base.authUrl, this.props.login.accessToken)
	                            .then((response) => {
	                                if (response.expires_in !== undefined) {
	                                    this.props.actions.setExpireInTime(response.expires_in)
	                                }
	                                
	                                userData.scope.forEach(function (role) {
	                           //      	if (role === "UPDATE_2F_SETTINGS_GRANT") {
				                        //     _this.props.actions.setUpdate2fSettingsGrant(true)
				                        // }

				                        // if (role === "CERTIFICATE_ISSUING_GRANT") {
				                        //     _this.props.actions.setCertificateIssuingGrant(true)
				                        // }

	                                    if (role === "VTMS_ADMINISTRATOR") {
	                                        containsRole = true; 
	                                    } else {
	                                        return;
	                                    }
	                                });


	                                if (containsRole) {
	                                    this.props.actions.getUserProfile(this.props.base.authUrl, this.props.login.accessToken)
	                                    .then((response) => {
	                                        _this.props.actions.setLoggedInUserData(response);
	                                        _this.props.actions.setAllUserFields(response);

	                                        _this.props.actions.getPublicKeyCurrentUtcDateTime(this.props.base.serviceUrl, this.props.login.accessToken)
	                                        .then((response) => {
	                                            _this.props.actions.setPublicKeyCurrentUtcDateTime(response);
	                                            _this.props.actions.setIsLoggedIn(true);
	                                            _this.setTime();
	                                        })
	                                    })
	                                } else {
	                                    alert(i18next.t("premissionDenied"));
	                                }
	                            })
	                        }
	                    })
	                }
	            }
	    	})
		}
	}

	render() {
		return (<div>
				{
					this.props.tokenList.selectedToken !== null
					? <TokenView />
					: this.props.base.activeTab === "trash" 
                    ? <TrashContainer />
                    : <Body />
				}
				{
					this.props.base.showSessionEndHandler
					? <SessionEndPopup />
		            : null
		        }
                {
                    this.props.base.showUserInfo
                    ? <UserInfoPopup />
                    : null
                }
		        {
					this.props.base.needReLogin
					? this.login()
					: null
				}
			<span id="clickMe" style={{position:"absolute", bottom: "0px", right: "0px", color: "transparent"}}>{this.state.seconds}</span>
		</div>);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        tokenList: state.tokenListReducer,
        search: state.tokenSearchReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
		setToken, setExpireInTime, setIsLoggedIn,
		login, verifyToken, getUserProfile, getPublicKeyCurrentUtcDateTime,
		setLoggedInUserData, setAllUserFields,
		setPublicKeyCurrentUtcDateTime, setErrorResponse,
		init2f, login2f, setTwoFactorEnabled,
		clearTwoFactorEnabled, clearTwoFactorCode,
		setPasswordInput, setUpdate2fSettingsGrant,
		getTokenList, setTokenList, setALLPaginationParams,
		setNeedReLogin, setShowSessionEndHandler
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BodyContainer);