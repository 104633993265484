import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { pageUrl } from '../actions/helpers';
import { getTokenInTrashList } from '../actions/api';
import { setTokenInTrashList, setALLPaginationParamsForTrash, setTokenInTrashSearchOwnerId,
		setTokenInTrashSearchOwnerCommonName, setTokenInTrashSearchOwnerDrfou, setTokenInTrashSearchOwnerEdrpou, setTokenInTrashSearchOwnerOperator, setTokenInTrashSearchOperator } from '../actions/localStates';

import TokenInTrashSearchView from '../components/TokenInTrashSearchView'

class TokenInTrashSearchContainer extends Component {

	constructor(props) {
        super(props);

        this.search = this.search.bind(this);
        this.refresh = this.refresh.bind(this);
    }


	componentDidMount() {

	}

	search () {
        var ownerId: null, ownerCommonName: null, ownerDrfou: null, ownerEdrpou: null,
            ownerData = this.props.search.ownerParams, ownerOperator = null;
        const re = /^[0-9+-?]+$/;

        if (ownerData !== null && ownerData !== undefined && ownerData.length > 0 && re.test(ownerData)) {
            if (ownerData.length > 8) {
                // eslint-disable-next-line
                ownerDrfou = ownerData !== null ? "\^" + ownerData : null;
                ownerEdrpou = null;
                ownerId = null;
                ownerCommonName = null;
                ownerOperator = null;
                this.props.actions.setTokenInTrashSearchOwnerDrfou(ownerDrfou)
                this.props.actions.setTokenInTrashSearchOwnerEdrpou(null)
                this.props.actions.setTokenInTrashSearchOwnerId(null)
                this.props.actions.setTokenInTrashSearchOwnerCommonName(null)
                this.props.actions.setTokenInTrashSearchOwnerOperator(null)
            } else if (this.props.search.ownerParams.length === 8) {
                ownerDrfou = null;
                // eslint-disable-next-line
                ownerEdrpou = ownerData !== null ? "\^" + ownerData : null;
                ownerId = null;
                ownerCommonName = null;
                ownerOperator = null;
                this.props.actions.setTokenInTrashSearchOwnerDrfou(null)
                this.props.actions.setTokenInTrashSearchOwnerId(null)
                this.props.actions.setTokenInTrashSearchOwnerCommonName(null)
                this.props.actions.setTokenInTrashSearchOwnerEdrpou(ownerEdrpou)
                this.props.actions.setTokenInTrashSearchOwnerOperator(null)
            } else {
                // eslint-disable-next-line
                ownerDrfou = ownerData !== null ? "\^" + ownerData : null;
                // eslint-disable-next-line
                ownerEdrpou = ownerData !== null ? "\^" + ownerData : null;
                ownerId = null;
                ownerCommonName = null;
                ownerOperator = "OR";
                this.props.actions.setTokenInTrashSearchOwnerId(null)
                this.props.actions.setTokenInTrashSearchOwnerDrfou(ownerDrfou)
                this.props.actions.setTokenInTrashSearchOwnerEdrpou(ownerEdrpou)
                this.props.actions.setTokenInTrashSearchOwnerOperator("OR")
            }
        } else {
            if (ownerData !== null && ownerData !== undefined && ownerData.length > 0) {
                ownerOperator = "OR";
                this.props.actions.setTokenInTrashSearchOwnerOperator("OR")
            }

            ownerId = ownerData;
            ownerCommonName = ownerData;
            ownerEdrpou = null;
            ownerDrfou = null;
            ownerOperator = ownerOperator !== null ? ownerOperator : null;
            this.props.actions.setTokenInTrashSearchOwnerEdrpou(null)
            this.props.actions.setTokenInTrashSearchOwnerDrfou(null)
            this.props.actions.setTokenInTrashSearchOwnerId(ownerId)
            this.props.actions.setTokenInTrashSearchOwnerCommonName(ownerCommonName)
        }

        if (((this.props.search.trashInDateTimeNotBefore !== null && this.props.search.trashInDateTimeNotBefore.length > 0) && (this.props.search.trashOutDateTimeNotBefore !== null && this.props.search.trashOutDateTimeNotBefore.length > 0)) || ((this.props.search.trashInDateTimeNotBefore !== null && this.props.search.trashInDateTimeNotBefore.length > 0) && (this.props.search.trashOutDateTimeNotAfter !== null && this.props.search.trashOutDateTimeNotAfter.length > 0))) {
        	this.props.actions.setTokenInTrashSearchOperator("AND")
        }

        if (((this.props.search.trashInDateTimeNotAfter !== null && this.props.search.trashInDateTimeNotAfter.length > 0) && (this.props.search.trashOutDateTimeNotBefore !== null && this.props.search.trashOutDateTimeNotBefore.length > 0)) || ((this.props.search.trashInDateTimeNotAfter !== null && this.props.search.trashInDateTimeNotAfter.length > 0) && (this.props.search.trashOutDateTimeNotAfter !== null && this.props.search.trashOutDateTimeNotAfter.length > 0))) {
        	this.props.actions.setTokenInTrashSearchOperator("AND")
        }

        var searchParams = {
            "uri": this.props.search.uri,
            "operator": this.props.search.operator,
            "owner": {
                "id": ownerId,
                "commonName": ownerCommonName,
                "drfou": ownerDrfou,
                "edrpou": ownerEdrpou,
                "operator": ownerOperator
            },
            "trashInDateTimeNotBefore": this.props.search.trashInDateTimeNotBefore,
		    "trashInDateTimeNotAfter": this.props.search.trashInDateTimeNotAfter,
		    "trashOutDateTimeNotBefore": this.props.search.trashOutDateTimeNotBefore,
		    "trashOutDateTimeNotAfter": this.props.search.trashOutDateTimeNotAfter
        }

        var params = {
            page: this.props.page.number,
            size: this.props.page.size
        }

        var urlParams = this.props.actions.pageUrl(params)

        this.props.actions.getTokenInTrashList(this.props.base.serviceUrl, urlParams, searchParams, this.props.login.accessToken)
        .then((response) => {

            this.props.actions.setTokenInTrashList(response.content)
            this.props.actions.setALLPaginationParamsForTrash(response.page)

            console.log(response)
        });
    }

	refresh () {
        var searchParams = {
            "uri": null,
		    "operator" : null,
		    "owner": {
		        "id": null,
		        "commonName": null,
		        "drfou": null,
		        "edrpou": null,
		        "externalId" : null,
		        "operator" : null
		    },
		    "trashInDateTimeNotBefore": null,
		    "trashInDateTimeNotAfter": null,
		    "trashOutDateTimeNotBefore": null,
		    "trashOutDateTimeNotAfter": null
        }

        this.props.actions.getTokenInTrashList(this.props.base.serviceUrl, "", searchParams, this.props.login.accessToken)
        .then((response) => {

            this.props.actions.setTokenInTrashList(response.content)
            this.props.actions.setALLPaginationParamsForTrash(response.page)

            console.log(response)
        });
    }

	render() {
		return (
			<TokenInTrashSearchView searchToken={this.search} refresh={this.refresh} />
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        search: state.tokenInTrashSearchReducer,
        login: state.loginReducer,
        page: state.trashPaginationReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    getTokenInTrashList, setTokenInTrashList, setALLPaginationParamsForTrash,
	    pageUrl, setTokenInTrashSearchOwnerId, setTokenInTrashSearchOwnerCommonName, setTokenInTrashSearchOwnerDrfou,
	    setTokenInTrashSearchOwnerEdrpou, setTokenInTrashSearchOwnerOperator, setTokenInTrashSearchOperator
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenInTrashSearchContainer);