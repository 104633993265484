import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { login, verifyToken, getUserProfile, getPublicKeyCurrentUtcDateTime, init2f, login2f } from '../actions/api';
import { setIsLoggedIn, setToken, setLoggedInUserData, setExpireInTime, setAllUserFields, setPublicKeyCurrentUtcDateTime, setTwoFactorEnabled,
        clearTwoFactorEnabled, clearTwoFactorCode, setPasswordInput, setUpdate2fSettingsGrant, setIs2fEnabled, setTimeDelta, setActiveTab } from '../actions/localStates'

import LoginView from '../components/LoginView';

class LoginContainer extends Component {

	constructor(props) {
    super(props);

        this.state = {
            token: ""
        }

        this.loginRef = React.createRef();

        this.login = this.login.bind(this);
        this.login2f = this.login2f.bind(this);
    }

	componentDidMount() {

	}

    login2f () {
        var userData, _this = this;
        var containsRole = false;
        var error = false;

        var code = {
            "twoFactorCode": this.props.login.twoFactorCode
        }
        
        
        this.props.actions.login2f(this.props.base.authUrl, code, this.props.login.accessToken)
        .then((response) => {
            if (response.code !== undefined) {
                this.props.actions.clearTwoFactorEnabled();
                this.props.actions.clearTwoFactorCode();
                this.props.actions.setPasswordInput("");
                error = true;
                alert(response.message);
                return
            }

            function parseJwt (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            };

            this.props.actions.setToken(response.access_token);
            userData = parseJwt(response.access_token);
        })
        .then(() => {
            if (!error) {
                this.props.actions.verifyToken(this.props.base.authUrl, this.props.login.accessToken)
                .then((response) => {
                    if (response.expires_in !== undefined) {
                        this.props.actions.setExpireInTime(response.expires_in)
                    }

                    userData.scope.forEach(function (role) {
                        // if (role === "UPDATE_2F_SETTINGS_GRANT") {
                        //     _this.props.actions.setUpdate2fSettingsGrant(true)
                        // }

                        // if (role === "CERTIFICATE_ISSUING_GRANT") {
                        //     _this.props.actions.setCertificateIssuingGrant(true)
                        // }

                        if (role === "VTMS_ADMINISTRATOR") {
                            containsRole = true; 
                        } else {
                            return;
                        }
                    });


                    if (containsRole) {
                        this.props.actions.getUserProfile(this.props.base.authUrl, this.props.login.accessToken)
                        .then((response) => {
                            _this.props.actions.setLoggedInUserData(response);
                            _this.props.actions.setAllUserFields(response);

                            _this.props.actions.getPublicKeyCurrentUtcDateTime(this.props.base.serviceUrl, this.props.login.accessToken)
                            .then((response) => {
                                var delta = new Date(response.currentUtcDateTime).getTime() - Date.now()
                                _this.props.actions.setTimeDelta(delta)
                                _this.props.actions.setTwoFactorEnabled(false);
                                _this.props.actions.clearTwoFactorCode();
                                _this.props.actions.setPublicKeyCurrentUtcDateTime(response);
                                 _this.props.actions.setActiveTab("body")
                                _this.props.actions.setIsLoggedIn(true);
                            })
                        })
                    } else {
                        alert(i18next.t("premissionDenied"));
                    }
                })
            }
        })
    }

	login () {
        var userData, _this = this, user;
        var containsRole = false;
        var error = false;

        var substring = "handyman";

        if (this.props.base.selectedAuthProvider.indexOf(substring) !== -1) {
            user = {
                "grant_type" : "password",
                "user" : this.props.login.login,
                "client_id" : this.props.base.clientId,
                "password" : this.props.login.password
            }
        } else {
            user = {
                "grant_type" : "password",
                "username" : this.props.login.login,
                "client_id" : this.props.base.clientId,
                "password" : this.props.login.password
            }
        }

        var data = {
            twoFactorCode: ""
        }

    	this.props.actions.login(this.props.base.selectedAuthProvider, user)
    	.then((response) => {
            if (response.code !== undefined) {
                alert(response.message);
                return
            }

            function parseJwt (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            };

            this.props.actions.setToken(response.access_token);
            userData = parseJwt(response.access_token);
            console.log(userData)

            if (userData.twof) {
                this.props.actions.init2f(this.props.base.authUrl, data, this.props.login.accessToken)
                .then((response) => {
                    console.log(response);
                })
                this.props.actions.setTwoFactorEnabled(true);
                this.props.actions.setIs2fEnabled(true);
                return;
            } else {
                if (!userData.twof) {
                    this.props.actions.login2f(this.props.base.authUrl, user, this.props.login.accessToken)
                    .then((response) => {
                        if (response.code !== undefined) {
                            this.props.actions.clearTwoFactorEnabled();
                            this.props.actions.clearTwoFactorCode();
                            this.props.actions.setPasswordInput("");
                            error = true;
                            alert(response.message);
                            return
                        }

                        function parseJwt (token) {
                            var base64Url = token.split('.')[1];
                            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                            }).join(''));

                            return JSON.parse(jsonPayload);
                        };
                        this.props.actions.setToken(response.access_token);
                        userData = parseJwt(response.access_token);
                    })
                    .then(() => {
                        if (!error) {
                            this.props.actions.verifyToken(this.props.base.authUrl, this.props.login.accessToken)
                            .then((response) => {
                                if (response.expires_in !== undefined) {
                                    this.props.actions.setExpireInTime(response.expires_in)
                                }
                                
                                userData.scope.forEach(function (role) {
                                    // if (role === "UPDATE_2F_SETTINGS_GRANT") {
                                    //     _this.props.actions.setUpdate2fSettingsGrant(true)
                                    // }

                                    // if (role === "CERTIFICATE_ISSUING_GRANT") {
                                    //     _this.props.actions.setCertificateIssuingGrant(true)
                                    // }

                                    if (role === "VTMS_ADMINISTRATOR") {
                                        containsRole = true; 
                                    } else {
                                        return;
                                    }
                                });


                                if (containsRole) {
                                    this.props.actions.getUserProfile(this.props.base.authUrl, this.props.login.accessToken)
                                    .then((response) => {
                                        _this.props.actions.setLoggedInUserData(response);
                                        _this.props.actions.setAllUserFields(response);

                                        _this.props.actions.getPublicKeyCurrentUtcDateTime(this.props.base.serviceUrl, this.props.login.accessToken)
                                        .then((response) => {
                                            var delta = new Date(response.currentUtcDateTime).getTime() - Date.now()
                                            _this.props.actions.setTimeDelta(delta)
                                            _this.props.actions.setPublicKeyCurrentUtcDateTime(response);
                                            _this.props.actions.setActiveTab("body")
                                            _this.props.actions.setIsLoggedIn(true);
                                        })
                                    })
                                } else {
                                    alert(i18next.t("premissionDenied"));
                                }
                            })
                        }
                    })
                }
            }
        })
	}

	render() {
		return (
			<div>
				<LoginView loginUser={this.login} loginUser2f={this.login2f} forwardRef={this.loginRef}  />
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	login, setIsLoggedIn,
        verifyToken, setToken,
        setLoggedInUserData,
        setExpireInTime,
        getUserProfile,
        setAllUserFields,
        getPublicKeyCurrentUtcDateTime,
        setPublicKeyCurrentUtcDateTime,
        init2f, login2f, setTwoFactorEnabled,
        clearTwoFactorEnabled, clearTwoFactorCode,
        setPasswordInput,
        setUpdate2fSettingsGrant, setIs2fEnabled,
        setTimeDelta, setActiveTab
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);