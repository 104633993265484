export const setIsIEBrowser = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_IE_BROWSER',
        payload: value
    })
}

export const setExpireInTime = (value) => (dispatch) => {
    dispatch({
        type: 'SET_EXPIRE_IN_TIME',
        payload: value
    })
}

export const setTokenExpiredAfterSec = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_EXPIRED_AFTER_SEC',
        payload: value
    })
}

export const setDefaults = (value) => (dispatch) => {
    dispatch({
        type: 'SET_DEFAULTS',
        payload: value
    })
}

export const setLoginInput = (value) => (dispatch) => {
    dispatch({
        type: 'SET_LOGIN_INPUT',
        payload: value
    })
}

export const setPasswordInput = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PASSWORD_INPUT',
        payload: value
    })
}

export const setIsLoggedIn = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_LOGGED_IN',
        payload: value
    })
}

export const setToken = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN',
        payload: value
    })
}

export const clearToken = (value) => (dispatch) => {
    dispatch({
        type: 'CLEAR_TOKEN',
        payload: value
    })
}

export const setLoggedInUserData = (value) => (dispatch) => {
    dispatch({
        type: 'SET_LOGGED_IN_USER_DATA',
        payload: value
    })
}

export const clearLoggedInUserData = (value) => (dispatch) => {
    dispatch({
        type: 'CLEAR_LOGGED_IN_USER_DATA',
        payload: value
    })
}

export const setAllConfigFields = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_CONFIG_FIELDS',
        payload: value
    })
}

export const setVersion = (data) => (dispatch) => {
    dispatch({
        type: 'SET_VERSION',
        payload: data
    })
}

export const setAllUserFields = (user) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_USER_FIELDS',
        payload: user
    })
}

export const setSelectedUser = (user) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_USER',
        payload: user
    })
}

export const setShowUserInfo = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_USER_INFO',
        payload: value
    })
}

export const setGlobalProcessing = (value) => (dispatch) => {
    dispatch({
        type: 'SET_GLOBAL_PROCESSING',
        payload: value
    })
}

export const setPublicKeyCurrentUtcDateTime = (data) => (dispatch) => {
    dispatch({
        type: 'SET_PUBLIC_KEY_CURRENT_UTC_DATE_TIME',
        payload: data
    })
}

export const clearPublicKeyCurrentUtcDateTime = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_PUBLIC_KEY_CURRENT_UTC_DATE_TIME',
        payload: null
    })
}

export const setShowActionResult = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_ACTION_RESULT',
        payload: value
    })
}

export const setActionResultData = (data) => (dispatch) => {
    dispatch({
        type: 'SET_ACTION_RESULT_DATA',
        payload: data
    })
}

export const setActionResultArr = (data) => (dispatch) => {
    dispatch({
        type: 'SET_ACTION_RESULT_ARR',
        payload: data
    })
}

export const setErrorResponse = (data) => (dispatch) => {
    dispatch({
        type: 'SET_ERROR_RESPONSE',
        payload: data
    })
}

export const clearErrorRespons = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_ERROR_RESPONSE',
        payload: null
    })
}

export const setTwoFactorEnabled = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_ENABLED',
        payload: value
    })
}

export const clearTwoFactorEnabled = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TWO_FACTOR_ENABLED',
        payload: null
    })
}

export const setTwoFactorCode = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TWO_FACTOR_CODE',
        payload: value
    })
}

export const clearTwoFactorCode = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TWO_FACTOR_CODE',
        payload: null
    })
}

export const setSelectedProvider = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_PROVIDER',
        payload: value
    })
}

export const setNeedRefresh = (value) => (dispatch) => {
    dispatch({
        type: 'SET_NEED_REFRESH',
        payload: value
    })
}

export const setUpdate2fSettingsGrant = (value) => (dispatch) => {
    dispatch({
        type: 'SET_UPDATE_2F_SETTINGS_GRANT',
        payload: value
    })
}

export const setIs2fEnabled = (value) => (dispatch) => {
    dispatch({
        type: 'SET_IS_2F_ENABLED',
        payload: value
    })
}

export const setShowLogoutHandler = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_LOGOUT_HANDLER',
        payload: value
    })
}

export const setTokenList = (list) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_LIST',
        payload: list
    })
}

export const setTokenInTrashList = (list) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_IN_TRASH_LIST',
        payload: list
    })
}

export const setALLPaginationParams = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_PAGINATION_PARAMS',
        payload: value
    })
}

export const setALLPaginationParamsForTrash = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ALL_PAGINATION_PARAMS_FOR_TRASH',
        payload: value
    })
}

export const setPageNumber = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PAGE_NUMBER',
        payload: value
    })
}

export const setPageNumberTrash = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PAGE_NUMBER_TRASH',
        payload: value
    })
}

export const setPageSize = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PAGE_SIZE',
        payload: value
    })
}

export const setPageSizeTrash = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PAGE_SIZE_TRASH',
        payload: value
    })
}

export const setTotalElements = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOTAL_ELEMENTS',
        payload: value
    })
}

export const setTotalElementsTrash = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOTAL_ELEMENTS_TRASH',
        payload: value
    })
}

export const setTotalPages = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOTAL_PAGES',
        payload: value
    })
}

export const setTotalPagesTrash = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOTAL_PAGES_TRASH',
        payload: value
    })
}

export const setSelectedAll = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_ALL',
        payload: value
    })
}

export const setSelectedAllTrash = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_ALL_TRASH',
        payload: value
    })
}

export const setSelectedToken = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SELECTED_TOKEN',
        payload: value
    })
}

export const clearSelectedToken = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_SELECTED_TOKEN',
        payload: null
    })
}

export const setTokenEnquire = (data) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_ENQUIRE',
        payload: data
    })
}

export const clearTokenEnquire = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_TOKEN_ENQUIRE',
        payload: null
    })
}

export const changeSearchState = (state) => (dispatch) => {
    dispatch({
        type: 'CHANGE_SEARCH_STATE',
        payload: state
    })
}

export const changeSearchInTrashState = (state) => (dispatch) => {
    dispatch({
        type: 'CHANGE_SEARCH_IN_TRASH_STATE',
        payload: state
    })
}

export const setTokenInTrashSearchUri = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_IN_TRASH_SEARCH_URI',
        payload: value
    })
}

export const setTokenInTrashSearchOperator = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_IN_TRASH_SEARCH_OPERATOR',
        payload: value
    })
}

export const setTokenInTrashSearchTrashInDateNotBefore = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_IN_TRASH_SEARCH_TRASH_IN_DATE_NOT_BEFORE',
        payload: value
    })
}

export const setTokenInTrashSearchTrashInDateNotAfter = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_IN_TRASH_SEARCH_TRASH_IN_DATE_NOT_AFTER',
        payload: value
    })
}

export const setTokenInTrashSearchTrashOutDateNotBefore = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_IN_TRASH_SEARCH_TRASH_OUT_DATE_NOT_BEFORE',
        payload: value
    })
}

export const setTokenInTrashSearchTrashOutDateNotAfter = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_IN_TRASH_SEARCH_TRASH_OUT_DATE_NOT_AFTER',
        payload: value
    })
}

export const setTokenInTrashSearchOwnerId = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_IN_TRASH_SEARCH_OWNER_ID',
        payload: value
    })
}

export const setTokenInTrashSearchOwnerCommonName = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_IN_TRASH_SEARCH_OWNER_COMMON_NAME',
        payload: value
    })
}

export const setTokenInTrashSearchOwnerDrfou = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_IN_TRASH_SEARCH_OWNER_DRFOU',
        payload: value
    })
}

export const setTokenInTrashSearchOwnerEdrpou = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_IN_TRASH_SEARCH_OWNER_EDRPOU',
        payload: value
    })
}

export const setTokenInTrashSearchOwnerOperator = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_IN_TRASH_SEARCH_OWNER_OPERATOR',
        payload: value
    })
}

export const setTokenInTrashSearchOwnerParams = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_IN_TRASH_SEARCH_OWNER_PARAMS',
        payload: value
    })
}

export const clearAllTokenInTrashSearchFields = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_ALL_TOKEN_IN_TRASH_SEARCH_FIELDS',
        payload: null
    })
}

export const setTokenSearchUri = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_URI',
        payload: value
    })
}

export const setTokenSearchTitle = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_TITLE',
        payload: value
    })
}

export const setTokenSearchStatus = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_STATUS',
        payload: value
    })
}

export const setTokenSearchDateNotBefore = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_DATE_NOT_BEFORE',
        payload: value
    })
}

export const setTokenSearchDateNotAfter = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_DATE_NOT_AFTER',
        payload: value
    })
}

export const setTokenSearchOwnerId = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_OWNER_ID',
        payload: value
    })
}

export const setTokenSearchOwnerCommonName = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_OWNER_COMMON_NAME',
        payload: value
    })
}

export const setTokenSearchOwnerDrfou = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_OWNER_DRFOU',
        payload: value
    })
}

export const setTokenSearchOwnerEdrpou = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_OWNER_EDRPOU',
        payload: value
    })
}

export const setTokenSearchConfidantId = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_CONFIDANT_ID',
        payload: value
    })
}

export const setTokenSearchConfidantCommonName = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_CONFIDANT_COMMON_NAME',
        payload: value
    })
}

export const setTokenSearchConfidantDrfou = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_CONFIDANT_DRFOU',
        payload: value
    })
}

export const setTokenSearchConfidantEdrpou = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_CONFIDANT_EDRPOU',
        payload: value
    })
}

export const clearAllTokenSearchFields = () => (dispatch) => {
    dispatch({
        type: 'CLEAR_ALL_TOKEN_SEARCH_FIELDS',
        payload: null
    })
}

export const setTokenSearchOwnerParams = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_OWNER_PARAMS',
        payload: value
    })
}

export const setTokenSearchConfidantParams = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_CONFIDANT_PARAMS',
        payload: value
    })
}

export const setTokenSearchOwnerOperator = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_OWNER_OPERATOR',
        payload: value
    })
}

export const setTokenSearchConfidantOperator = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN_SEARCH_CONFIDANT_OPERATOR',
        payload: value
    })
}

export const setShowSessionEndHandler = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_SESSION_END_HANDLER',
        payload: value
    })
}

export const setNeedReLogin = (value) => (dispatch) => {
    dispatch({
        type: 'SET_NEED_RELOGIN',
        payload: value
    })
}

export const setNeedToLogout = (value) => (dispatch) => {
    dispatch({
        type: 'SET_NEED_TO_LOGOUT',
        payload: value
    })
}

export const setShowHsmSoPin = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_HSM_SO_PIN',
        payload: value
    })
}

export const setHsmSoPin = (value) => (dispatch) => {
    dispatch({
        type: 'SET_HSM_SO_PIN',
        payload: value
    })
}

export const setTimeDelta = (value) => (dispatch) => {
    dispatch({
        type: 'SET_TIME_DELTA',
        payload: value
    })
}

export const setShowHsmSoPinForReset = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SHOW_HSM_SO_PIN_FOR_RESET',
        payload: value
    })
}

export const setHsmSoPinForReset = (value) => (dispatch) => {
    dispatch({
        type: 'SET_HSM_SO_PIN_FOR_RESET',
        payload: value
    })
}

export const setHsmSoPukForReset = (value) => (dispatch) => {
    dispatch({
        type: 'SET_HSM_SO_PUK_FOR_RESET',
        payload: value
    })
}

export const setActiveTab = (value) => (dispatch) => {
    dispatch({
        type: 'SET_ACTIVE_TAB',
        payload: value
    })
}