export const getConfiguration = (url, token) => (dispatch) => {
  return fetch(url + "/configuration/", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json()
      }
    })
}

export const login = (url, user) => (dispatch) => {
  return fetch(url + "/token", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      dataType: "json",
      body: JSON.stringify(user)
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json();
      }
    })
}

export const getRole = (url, roleId, token) => (dispatch) => {
  return fetch(url + "/role/" + roleId, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const getRoles = (url, token) => (dispatch) => {
  return fetch(url + "/role", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const getDefaults = () => (dispatch) => {
    return fetch(process.env.PUBLIC_URL + "/config.json", {
        method: 'GET',
        dataType: "json"
      }).then(response => {
        return response.json()
    });
}

export const getVersion = () => (dispatch) => {
    return fetch(process.env.PUBLIC_URL + "/version.json", {
        method: 'GET',
        dataType: "json"
      }).then(response => {
        return response.json()
    });
}

export const verifyToken = (url, token) => (dispatch) => {
  return fetch(url + "/token", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json'
      },
      body: token,
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const getUserProfile = (url, token) => (dispatch) => {

  return fetch(url + "/user/self/profile", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const getPublicKeyCurrentUtcDateTime = (url, token) => (dispatch) => {

  return fetch(url + "/protectedParameters/settings", {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      }
    }).then(response => {
      if(response.ok) {
        return response.json()
      }
    })
}

export const init2f = (url, data, token) => (dispatch) => {
  return fetch(url + "/token.user.2f", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json();
      }
    })
}

export const login2f = (url, data, token) => (dispatch) => {
  return fetch(url + "/token.user", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return response.json();
      } else {
        return response.json();
      }
    })
}

export const toTrash = (url, data, token) => (dispatch) => {
  return fetch(url + "/so/trash.put", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json();
      }
    })
}

export const restoreFromTrash = (url, data, token) => (dispatch) => {
  return fetch(url + "/so/trash.restore", {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      body: JSON.stringify(data),
      dataType: "json"
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json();
      }
    })
}

export const getTokenList = (url, urlParams, searchParams, token) => (dispatch) => {

  return fetch(url + "/so/token.list" + urlParams, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json",
      body: JSON.stringify(searchParams)
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const getTokenInTrashList = (url, urlParams, searchParams, token) => (dispatch) => {

  return fetch(url + "/so/trash.list" + urlParams, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json",
      body: JSON.stringify(searchParams)
    }).then(response => {
      if(response.ok) {
        return response.json()
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const blockToken = (url, uri, token) => (dispatch) => {
  var data = {
    "ciHsmTokenUri": uri
  }

  return fetch(url + "/so/token.block", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json",
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const unblockToken = (url, uri, token) => (dispatch) => {
  var data = {
    "ciHsmTokenUri": uri
  }

  return fetch(url + "/so/token.unblock", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json",
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const removeToken = (url, uri, data, token) => (dispatch) => {

  return fetch(url + "/so/token.remove", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json",
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}

export const resetToken = (url, uri, data, token) => (dispatch) => {

  return fetch(url + "/so/token.reset", {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${token}`
      },
      dataType: "json",
      body: JSON.stringify(data)
    }).then(response => {
      if(response.ok) {
        return true;
      } else {
        return response.json().then(json => {
          return {
            contentType: response.headers.get("Content-Type"),
            json: json
          }
        })
      }
    })
}