const baseState = {
    number: 0,
    size: 20,
    totalElements: 0,
    totalPages: 0,
    userPaginationParamsSetted: false
}

const trashPaginationReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_ALL_PAGINATION_PARAMS_FOR_TRASH": {
			return {
				...state,
				number: payload.number,
			    size: payload.size,
			    totalElements: payload.totalElements,
			    totalPages: payload.totalPages
			}
		}

    	case "SET_PAGE_NUMBER_TRASH": {
			return {
				...state,
				number: payload
			}
		}

		case "SET_PAGE_SIZE_TRASH": {
			return {
				...state,
				size: payload
			}
		}

		case "SET_TOTAL_ELEMENTS_TRASH": {
			return {
				...state,
				totalElements: payload
			}
		}

		case "SET_TOTAL_PAGES_TRASH": {
			return {
				...state,
				totalPages: payload
			}
		}

        default:
            return state;
    }
}

export default trashPaginationReducer