const baseState = {
    trashList: [],
    selectedAll: false
}

const tokenInTrashListReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_TOKEN_IN_TRASH_LIST": {
			return {
				...state,
				list: payload
			}
		}

        case "SET_SELECTED_ALL_TRASH": {
            return {
                ...state,
                selectedAll: payload
            }
        }

        default:
            return state;
    }
}

export default tokenInTrashListReducer