const baseState = {
    uri: null,
    title: null,
    status: null,
    ownerId: null,
    ownerCommonName: null,
    ownerDrfou: null,
    ownerEdrpou: null,
    ownerOperator: null,
    confidantId: null,
    confidantCommonName: null,
    confidantDrfou: null,
    confidantEdrpou: null,
    createdDateTimeNotBefore: null,
    createdDateTimeNotAfter: null,
    ownerParams: null,
    confidantParams: null,
    confidantOperator: null
}

const tokenSearchReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_TOKEN_SEARCH_URI": {
			return {
				...state,
				uri: payload
			}
		}

		case "SET_TOKEN_SEARCH_TITLE": {
			return {
				...state,
				title: payload
			}
		}

		case "SET_TOKEN_SEARCH_STATUS": {
			return {
				...state,
				status: payload
			}
		}

		case "SET_TOKEN_SEARCH_DATE_NOT_BEFORE": {
			return {
				...state,
				createdDateTimeNotBefore: payload
			}
		}

		case "SET_TOKEN_SEARCH_DATE_NOT_AFTER": {
			return {
				...state,
				createdDateTimeNotAfter: payload
			}
		}

		case "SET_TOKEN_SEARCH_OWNER_ID": {
			return {
				...state,
				ownerId: payload
			}
		}

		case "SET_TOKEN_SEARCH_OWNER_COMMON_NAME": {
			return {
				...state,
				ownerCommonName: payload
			}
		}

		case "SET_TOKEN_SEARCH_OWNER_DRFOU": {
			return {
				...state,
				ownerDrfou: payload
			}
		}

		case "SET_TOKEN_SEARCH_OWNER_EDRPOU": {
			return {
				...state,
				ownerEdrpou: payload
			}
		}

		case "SET_TOKEN_SEARCH_OWNER_OPERATOR": {
			return {
				...state,
				ownerOperator: payload
			}
		}

		case "SET_TOKEN_SEARCH_CONFIDANT_ID": {
			return {
				...state,
				confidantId: payload
			}
		}

		case "SET_TOKEN_SEARCH_CONFIDANT_COMMON_NAME": {
			return {
				...state,
				confidantCommonName: payload
			}
		}

		case "SET_TOKEN_SEARCH_CONFIDANT_DRFOU": {
			return {
				...state,
				confidantDrfou: payload
			}
		}

		case "SET_TOKEN_SEARCH_CONFIDANT_EDRPOU": {
			return {
				...state,
				confidantEdrpou: payload
			}
		}

		case "SET_TOKEN_SEARCH_CONFIDANT_OPERATOR": {
			return {
				...state,
				confidantOperator: payload
			}
		}

		case "SET_TOKEN_SEARCH_OWNER_PARAMS": {
			return {
				...state,
				ownerParams: payload
			}
		}

		case "SET_TOKEN_SEARCH_CONFIDANT_PARAMS": {
			return {
				...state,
				confidantParams: payload
			}
		}

		case "CLEAR_ALL_TOKEN_SEARCH_FIELDS": {
			return {
				...state,
				uri: null,
			    title: null,
			    status: null,
			    ownerId: null,
			    ownerCommonName: null,
			    ownerDrfou: null,
			    ownerEdrpou: null,
			    confidantId: null,
			    ownerOperator: null,
			    confidantCommonName: null,
			    confidantDrfou: null,
			    confidantEdrpou: null,
			    confidantOperator: null,
			    createdDateTimeNotBefore: null,
			    createdDateTimeNotAfter: null,
			    ownerParams: null,
			    confidantParams: null
			}
		}

        default:
            return state;
    }
}

export default tokenSearchReducer