import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setShowHsmSoPin, setHsmSoPin } from '../actions/localStates'

class HsmSoPinPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {

      }

      this.onHsmSoPinChanged = this.onHsmSoPinChanged.bind(this);

      this.handle2FKeyUp = this.handle2FKeyUp.bind(this, 'twoFactorInput');

      this.closeHsmSoPin = this.closeHsmSoPin.bind(this);

      this.submit = this.submit.bind(this);
  }

  componentDidMount() {
      this.props.actions.setHsmSoPin("")
      var element = document.getElementById("2f");
      element.focus();
      document.addEventListener('keyup', this.handle2FKeyUp);
  }

  componentWillUnmount() {
      document.removeEventListener('keyup', this.handle2FKeyUp);
  }

  handle2FKeyUp(refName, e) {
      if (e.keyCode === 13 && refName === "twoFactorInput") {
          document.getElementById("submitPin").click();
          document.activeElement.blur();
      }
  }

  onHsmSoPinChanged (e) {
    this.props.actions.setHsmSoPin(e.target.value)
  }

  submit () {
    this.props.removeToken();
  }

  closeHsmSoPin () {
    this.props.actions.setShowHsmSoPin(false)
  }

  hoverPin() {
        this.setState({showPin: true})
  }

  outPin() {
      this.setState({showPin: false})
  }

  render() {
    let isEnabled = this.props.base.hsmSoPin >= 8
    ? true
    : false

      return (
          <div className='popup'>
              <div className={"popup_inner_user_token_pin"}>
                  <div className="col-12">
                  <h4 style={{paddingLeft: "15px", paddingTop: "30px"}}>{i18next.t("deleteTokenLabel")}</h4>
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingTop: "0px", paddingBottom: "0px"}}>{i18next.t("hsmSoPinTitle")}</label>
                          <div className="col-12 input-group">
                              <input id="2f" className="form-control" value={this.props.base.hsmSoPin} type={this.state.showPin ? "input" : "password"} onChange={this.onHsmSoPinChanged} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverPin.bind(this)} onMouseOut={this.outPin.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>

                      </div>
                      <div style={{float: "right", marginRight: "15px", paddingTop: "10px"}}>
                          <button className="btn btn-outline-info" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.closeHsmSoPin}>{i18next.t("cancelLabel")}</button>
                      </div>
                      <div style={{float: "right", marginRight: "5px", paddingTop: "10px"}}>
                          <button className="btn btn-info" id="submitPin" style={{marginLeft: "5px", marginBottom: "30px"}} disabled={!isEnabled} onClick={this.submit}>{i18next.t("okLabel")}</button>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        selectedClient: state.clientReducer,
        login: state.loginReducer,
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      setShowHsmSoPin, setHsmSoPin
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HsmSoPinPopup);