import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import {  } from "../actions/api"
import {  } from "../actions/localStates"
import { formatDate } from '../actions/helpers'

import saveAs from 'file-saver';

import CertificateInfoPopup from './CertificateInfoPopup'

class OtherKeysView extends Component {
	constructor() {
		super();
		this.state = {
			showActions: [],
			dropdownsSetted: false,
			showCertificateInfo: false,
			showPopup: false,
			info: null,
			showConfirmPopup: false,
			activeHandler: null,
			actionInfo: "",
			active2fOtherKeysHandler: "",
			confirm2fTitle: "",
	    	numberOfPinInput: 0,
	    	needToReEnterPin: false,
	    	isActiveView: false,
	    	id: ""
		};

		this.handleShowActions = this.handleShowActions.bind(this);
	    this.handleCloseActions = this.handleCloseActions.bind(this);
	}

	showMessagePopup () {
		if (this.state.showPopup === true && this.state.needToReEnterPin === true) {
			if (this.state.numberOfPinInput > 0) {
				this.props.actions.setNeed2fConfirm(true);
			} else {
				this.setState({needToReEnterPin: false});
				this.setState({numberOfPinInput: 0});
			}
		}
    	this.setState({
          	showPopup: !this.state.showPopup
      	});
  	}

	handleShowActions (event) {
		event.preventDefault();
		this.setState({
				showActions: !this.state.showActions
			}, () => {
			document.addEventListener('click', this.handleCloseActions);
		});
	}

	handleCloseActions (event) { 
		this.setState({ showActions: false }, () => {
			document.removeEventListener('click', this.handleCloseActions);
		});
	}

	handleDownloadCertificate (id) {
		const byteCharacters = atob(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificates[id]);
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);

		const blob = new Blob([byteArray], {type: "application/octet-stream"});
		var fileName = id + ".cer";
		saveAs(blob, fileName);
	}

	handleDownloadCertificateRequest (id) {
		const byteCharacters = atob(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequests[id]);
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
		  byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);

		const blob = new Blob([byteArray], {type: "application/octet-stream"});
		var fileName = id + ".cer";
		saveAs(blob, fileName);
	}

	closeInfoPopup () {
		this.setState({
	        showCertificateInfo: !this.state.showCertificateInfo
	    });
	}

	showInfo (data) {
		this.setState({info: this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[data]});
		this.setState({showCertificateInfo: !this.state.showCertificateInfo});
	}

	componentDidMount() {
		var _this = this, state;
		var tempState = {};
		this.setState({showActions: []})

		if (this.props.tokenList.tokenEnquire && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached !== null) {
			this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.keyIdentifiers.forEach((key, index) => {
				if (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT !== undefined && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT === key) {
					return
				}

				if (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE !== undefined && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE === key) {
					return
				}

				if (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key] !== undefined) {
					tempState[key] = false;
				}

				if (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key] === undefined && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[key] === undefined) {
					tempState[key] = false;
				}

			});
		}

		this.setState({showActions: tempState})
		this.setState({dropdownsSetted: true})
		state = tempState

		document.addEventListener('click', function (e) {
			if (e.target && e.target.id && state.hasOwnProperty(e.target.id)) {
				state[e.target.id] = true;
			} else {
				for (var key in state) {
				    state[key] = false
				}
			}

			_this.setState({showActions: state})
		});
	}

	componentWillUnmount () {
		document.removeEventListener('click', this);
	}

	closeConfirmPopup () {
		this.setState({
	        showConfirmPopup: false
	    });
	}

	componentDidUpdate() {
		
	}

	_renderData(key) {
		var data = [];
		if (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT !== undefined && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.AGREEMENT === key) {
			return
		}

		if (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE !== undefined && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.activeKeys.SIGNATURE === key) {
			return
		}

		if (this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached !== null && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key] !== undefined) {
			data.push(
				<div key={key}>
					<div style={{paddingLeft: "0px", paddingRight: "0px", marginBottom: "10px"}} className="table-border bg-color row col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "left"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="table-header">
								<label>{i18next.t("owner")}</label>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<label>
									{
										this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key].subject.commonName
									}
								</label>
								<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
									{
										i18next.t("drfou")
									}
									{
										this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key].extensions.drfou
									}
								</label>
								<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
									{
										i18next.t("edrpou")
									}
									{
										this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key].extensions.edrpou
									}
								</label>
							</div>
						</div>
						<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="table-header">
								<label>{i18next.t("appointment")}</label>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<label>
									{
										this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key].extensions.keyUsage.indexOf("DIGITAL_SIGNATURE") > -1
										? i18next.t("DIGITAL_SIGNATURE")
										: null
									}
									{
										this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key].extensions.keyUsage.indexOf("KEY_AGREEMENT") > -1
										? i18next.t("KEY_AGREEMENT")
										: null
									}
								</label>
							</div>
						</div>
						<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="table-header">
								<label>{i18next.t("key")}</label>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div>
									<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
										{
											i18next.t("algorithm")
										}
										{
											this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key].subjectPublicKeyInfo.algorithm === "1.2.840.10045.2.1" ? i18next.t("ECDSA-256") : (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key].subjectPublicKeyInfo.algorithm === "1.2.840.113549.1.1.1" ? i18next.t("RSA") : this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key].subjectPublicKeyInfo.algorithm)
										}
									</label>
								</div>
								<div>
									<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
										{
											i18next.t("identifire")
										}
									</label>
									<div className="margin-left-5" style={{wordWrap: "break-word", fontSize: "10px", textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
										{
											key
										}
									</div>
								</div>
							</div>
						</div>
						<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="table-header">
								<label>{i18next.t("certificate")}</label>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div>
									<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
										{
											i18next.t("notBefore")
										}
										{
											this.props.actions.formatDate(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key].notBefore)
										}
									</label>
								</div>
								<div>
									<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
										{
											i18next.t("notAfter")
										}
										{
											this.props.actions.formatDate(this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key].notAfter)
										}
									</label>
								</div>
								<div>
									<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
										{
											i18next.t("issuer")
										}
										{
											this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key].issuer.commonName
										}
									</label>
								</div>
							</div>
						</div>
						<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-1 col-md-1 col-sm-12 col-xs-12">
							<div className="table-header min-heigth-33">
								<label></label>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div className="dropdown" style={{marginTop: "5px"}}>
		                            <button type="button" id={key} className="btn btn-cog dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showActions[key] !== undefined ? this.state.showActions[key].open : false}>
										<span><i  id={key} style={{color: "#1C92C7", fontSize: "2.5em"}} className="fa fa-cog"></i></span>
									</button>
		                            <div className={(this.state.showActions[key] !== undefined && this.state.showActions[key] === true) ? "dropdown-menu dropdown-menu-right show" : "dropdown-menu dropdown-menu-right"}>
		                                {
		                                	this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key]
		                                	? <span className="dropdown-item pointer" onClick={this.handleDownloadCertificate.bind(this, key)}>{i18next.t("downloadCertificate")}</span>
		                                	: null
		                                }
		                                {
		                                	this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key]
		                                	? <span className="dropdown-item pointer" onClick={this.showInfo.bind(this, key)}>{i18next.t("certificateInfo")}</span>
		                                	: null
		                                }
		                            </div>
		                        </div>
							</div>
						</div>
					</div>
				</div>)
			return data;
		}

		if (this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateInfos[key] === undefined && this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.certificateRequestInfos[key] === undefined) {
			data.push(
				<div key={key}>
					<div style={{paddingLeft: "0px", paddingRight: "0px", marginBottom: "10px"}} className="table-border bg-color row col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "left"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="table-header min-heigth-33">
								<label></label>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							</div>
						</div>
						<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="table-header min-heigth-33">
								<label></label>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							</div>
						</div>
						<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="table-header">
								<label>{i18next.t("key")}</label>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div>
									<label className="col-12" style={{textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
										{
											i18next.t("identifire")
										}
									</label>
									<div className="margin-left-5" style={{wordWrap: "break-word", fontSize: "10px", textAlign: "left", paddingLeft: "0px", marginBottom: "0px"}}>
										{
											key
										}
									</div>
								</div>
							</div>
						</div>
						<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="table-header min-heigth-33">
								<label></label>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							</div>
						</div>
						<div style={{paddingLeft: "0px", paddingRight: "0px", textAlign: "center"}} className="col-lg-1 col-md-1 col-sm-12 col-xs-12">
							<div className="table-header min-heigth-33">
								<label></label>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div className="dropdown" style={{marginTop: "5px"}}>
		                            
		                        </div>
							</div>
						</div>
					</div>
				</div>)
		}

		return data;
	}

	render() {
		return (
			<div>
				{
					this.props.tokenList.list[this.props.tokenList.selectedToken].tokenContentDescriptorCached !== null && this.props.tokenList.tokenEnquireSetted
					? <div style={{marginTop: "10px"}}>
				    	{
							this.state.showActions && Object.keys(this.state.showActions).length === 0 && this.state.showActions.constructor === Object
							? null
							: <label>{i18next.t("otherKeys")}</label>
						}
						{
							this.props.tokenList.tokenEnquire.tokenContentDescriptorCached.keyIdentifiers.map((key, index) => {
								return (<div key={index}>
										{	
											this._renderData(key)
										}
									</div>)
							})
						}
						</div>
					: null
				}
				<div>
					{
						this.state.showCertificateInfo
						? <CertificateInfoPopup certInfo={this.state.info} closePopup={this.closeInfoPopup.bind(this)} />
						: null
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        tokenList: state.tokenListReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	formatDate
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherKeysView);