import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import paginationReducer from './paginationReducer';
import tokenListReducer from './tokenListReducer';
import tokenSearchReducer from './tokenSearchReducer'
import tokenInTrashSearchReducer from './tokenInTrashSearchReducer'
import tokenInTrashListReducer from './tokenInTrashListReducer'
import trashPaginationReducer from './trashPaginationReducer'

const baseState = {
    authUrl: "",
    serviceUrl: "",
    clientId: "",
    defaultsSetted: false,
    version: "",
    build: "",
    showUserInfo: false,
    searchIsOpen: false,
    isIEBrowser: false,
    domain: "",
    apps: [],
    globalProcessing: false,
    supportAD: "false",
    status: "success",
    action: "",
    message: "",
    resultMessagesArr: null,
    showActionResult: false,
    expires_in: null,
    selectedAuthProvider: "",
    authProviders:[],
    needRefresh: false,
    showLogoutHandler: false,
    numberOfPinInput: 3,
    showError: false,
    showSessionEndHandler: false,
    needReLogin: false,
    needToLogout: false,
    waitingTime: 0,
    timeToLogout: 15,
    showHsmSoPin: false,
    hsmSoPin: "",
    showHsmSoPinForReset: false,
    hsmSoPinForReset: "",
    hsmSoPukForReset: "",
    activeTab: "body",
    searchInTrashIsOpen: false
}

const base = (state = baseState, { type, payload }) => {

    switch (type) {
        case "CHANGE_SEARCH_STATE": {
            return {
                ...state,
                searchIsOpen: payload
            }
        }

        case "CHANGE_SEARCH_IN_TRASH_STATE": {
            return {
                ...state,
                searchInTrashIsOpen: payload
            }
        }

        case 'SET_SHOW_USER_INFO': {
            return {
                ...state,
                showUserInfo: payload
            }
        }

        case "SET_DEFAULTS": {
            return {
                ...state,
                authUrl: payload.authUrl,
                serviceUrl: payload.serviceUrl,
                clientId: payload.clientId,
                selectedAuthProvider: payload.authProviders[0].url || "https://ccs-dev-api.cipher.kiev.ua/ccs/auth-2",
                defaultsSetted: true,
                apps: payload.apps || [],
                domain: "." + payload.domain || "",
                supportAD: payload.supportAD || "false",
                authProviders: payload.authProviders || [],
                numberOfPinInput: parseInt(payload.numberOfPinInput, 10) || 3,
                waitingTime: parseInt(payload.waitingTimeSec, 10) || 30,
                timeToLogout: parseInt(payload.timeToLogoutMin, 10) || 15
            }
        }

        case 'SET_VERSION': {
            return {
                ...state,
                version: payload.version,
                build: payload.build
            }
        }

        case 'SET_IS_IE_BROWSER': {
            return {
                ...state,
                isIEBrowser: payload
            }
        }

        case 'SET_GLOBAL_PROCESSING': {
            return {
                ...state,
                globalProcessing: payload
            }
        }

        case "SET_SHOW_ACTION_RESULT": {
            return {
                ...state,
                showActionResult: payload
            }
        }

        case "SET_ACTION_RESULT_DATA": {
            return {
                ...state,
                status: payload.status,
                action: payload.action,
                message: payload.message
            }
        }

        case "SET_ACTION_RESULT_ARR": {
            return {
                ...state,
                resultMessagesArr: payload
            }
        }

        case "SET_EXPIRE_IN_TIME": {
            return {
                ...state,
                expires_in: payload
            }
        }

        case "SET_SELECTED_PROVIDER": {
            return {
                ...state,
                selectedAuthProvider: payload
            }
        }

        case "SET_NEED_REFRESH": {
            return {
                ...state,
                needRefresh: payload
            }
        }

        case "SET_SHOW_LOGOUT_HANDLER": {
            return {
                ...state,
                showLogoutHandler: payload
            }
        }

        case "SET_SHOW_ERROR": {
            return {
                ...state,
                showError: payload
            }
        }

        case "SET_SHOW_SESSION_END_HANDLER": {
            return {
                ...state,
                showSessionEndHandler: payload
            }
        }

        case "SET_NEED_RELOGIN": {
            return {
                ...state,
                needReLogin: payload
            }
        }

        case "SET_NEED_TO_LOGOUT": {
            return {
                ...state,
                needToLogout: payload
            }
        }

        case "SET_SHOW_HSM_SO_PIN": {
            return {
                ...state,
                showHsmSoPin: payload
            }
        }

        case "SET_HSM_SO_PIN": {
            return {
                ...state,
                hsmSoPin: payload
            }
        }

        case "SET_SHOW_HSM_SO_PIN_FOR_RESET": {
            return {
                ...state,
                showHsmSoPinForReset: payload
            }
        }

        case "SET_HSM_SO_PIN_FOR_RESET": {
            return {
                ...state,
                hsmSoPinForReset: payload
            }
        }

        case "SET_HSM_SO_PUK_FOR_RESET": {
            return {
                ...state,
                hsmSoPukForReset: payload
            }
        }

        case "SET_ACTIVE_TAB": {
            return {
                ...state,
                activeTab: payload
            }
        }

        default:
            return state;
    }

}

export default combineReducers({
    base,
    loginReducer,
    paginationReducer,
    tokenListReducer,
    tokenSearchReducer,
    tokenInTrashSearchReducer,
    tokenInTrashListReducer,
    trashPaginationReducer
});


