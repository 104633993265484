import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import platform from 'platform';

import i18next from 'i18next'

class AboutPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
      }

  }

  componentDidMount() {

  }

  javaVersion() {
    var result = null;

    for(var i = 0, size = navigator.mimeTypes.length; i < size; i += 1)
    {
        if((result = navigator.mimeTypes[i].type.match(/^application\/x-java-applet;jpi-version=(.*)$/)) !== null)
            return result[1];
    }
    return null;
  }

  showBrowserInfo() {
    alert(''
      + 'Browser name  = ' + platform.name + '\n'
      + 'Version  = ' + platform.version + '\n'
      + 'OS = ' + platform.os + '\n'
      + 'Description = ' + platform.description + '\n'
      + 'Java enabled = ' + navigator.javaEnabled()
    )
  }

  render() {
      return (
          <div className='popup'>
              <div className="popup_inner_about">
                <div className="col-12" style={{paddingTop: "30px"}}>
                  <h4>{i18next.t("headerTitle")}</h4>
                  <p></p>
                  <span>{i18next.t("version")} {this.props.base.version} build {this.props.base.build}</span><i className="fa fa-info-circle" onClick={this.showBrowserInfo} style={{"marginLeft": "7px", "cursor": "pointer"}}></i>
                  <p></p>
                  <span>{i18next.t("copyRightlabel")} &copy; {i18next.t("copyRightSecondPartlabel")}</span>
                  <p></p>
                  <span>{i18next.t("siteTitle")} <a href="https://www.cipher.com.ua/">www.cipher.com.ua</a> </span>
                </div>
                <div style={{float: "right", paddingTop: "10px"}}>
                    <button className="btn btn-info" style={{marginRight: "15px", width: "75px", marginBottom: "30px"}} onClick={this.props.closePopup}>Ok</button>
                </div>
              </div>
          </div>
      );
  }
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutPopup);