import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getTokenList, blockToken, unblockToken, removeToken, resetToken, toTrash } from '../actions/api'

import { pageUrl, cipher } from '../actions/helpers'

import { setPageSize, setErrorResponse, setTokenList, setALLPaginationParams, setSelectedAll, setSelectedToken, setTokenEnquire, changeSearchState,
		setShowHsmSoPin, setHsmSoPin, setShowHsmSoPinForReset, setActiveTab } from '../actions/localStates'

import i18next from 'i18next'

import ConfidantsPopup from './ConfidantsPopup'
import TokenSearchContainer from '../containers/TokenSearchContainer'
import HsmSoPinPopup from './HsmSoPinPopup'
import HsmSoPinForResetPopup from './HsmSoPinForResetPopup'

import first from '../img/first.svg'
import last from '../img/last.svg'

let searchBar;

class Body extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	elementsCountArray: [10, 20, 50],
	    	showItemsCountForm: false,
	    	current_page: 1,
	    	current_user: null,
	    	current_event: null,
	    	records_per_page: this.props.page.size,
	    	checkedArr: [],
	    	showConfidants: false,
	    	selectedToken: null,
	    	searchOpen: false,
	    	showSearchForm: false,
	    	breakLoop: false
	    }

	    this.prevPage = this.prevPage.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.firstPage = this.firstPage.bind(this);
		this.lastPage = this.lastPage.bind(this);
		this.changePage = this.changePage.bind(this);
		this.numPages = this.numPages.bind(this);

		this.handleShowCountForm = this.handleShowCountForm.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);

		this.handleSearchClick = this.handleSearchClick.bind(this);

		this.blocked = this.blocked.bind(this);
		this.activate = this.activate.bind(this);
		this.remove = this.remove.bind(this);
		this.resetToken = this.resetToken.bind(this);

		this.showSoPinPopup = this.showSoPinPopup.bind(this);
		this.showSoPinForResetPopup = this.showSoPinForResetPopup.bind(this);

		this.changeTab = this.changeTab.bind(this);

		this.putToTrash = this.putToTrash.bind(this);

		// this.copyToClipboard = this.copyToClipboard.bind(this);
	}

	componentDidMount() {
		var _this = this;
		_this.props.actions.changeSearchState(false)
		searchBar = document.getElementById("search-bar");
		searchBar.addEventListener( 'webkitTransitionEnd', function( event ) { _this.setState({showSearchForm: _this.state.searchOpen}) }, false );
	}

	componentDidUpdate(prevProps) {
		if (this.props.search !== prevProps.search) {

			this.setState({checkedArr: []})
		}
	}

	async blockToken (uri, index) {
		var _this = this;
		await this.props.actions.blockToken(this.props.base.serviceUrl, uri, this.props.login.accessToken)
		.then((response) => {
			if (response !== null && response.contentType === "application/ccs.api.error+json;charset=UTF-8") {
				alert(response.json.message);
			}

			if (index === _this.state.checkedArr.length - 1) {
				_this.refreshList();
				_this.props.actions.setSelectedAll(false)
				_this.setState({"checkedArr": []})
			}
		})
	}

	blocked () {
    	for (var i = 0; i < this.state.checkedArr.length; i += 1) {
    		this.blockToken(this.props.tokenList.list[this.state.checkedArr[i]].uri, i)
		}
		// this.props.actions.setSelectedAll(false)
		// this.setState({"checkedArr": []})
		// this.refreshList()

	}

	async unblockToken (uri, index) {
		var _this = this;
		await this.props.actions.unblockToken(this.props.base.serviceUrl, uri, this.props.login.accessToken)
		.then((response) => {
			if (response !== null && response.contentType === "application/ccs.api.error+json;charset=UTF-8") {
				alert(response.json.message);
			}

			if (index === _this.state.checkedArr.length - 1) {
				_this.refreshList();
				_this.props.actions.setSelectedAll(false)
				_this.setState({"checkedArr": []})
			}
		})
	}

	activate () {
    	for (var i = 0; i < this.state.checkedArr.length; i += 1) {
    		this.unblockToken(this.props.tokenList.list[this.state.checkedArr[i]].uri, i)
		}
		// this.props.actions.setSelectedAll(false)
		// this.setState({"checkedArr": []})
		// this.refreshList()
	}

	async putToTrashToken (uri, newDate, index) {
		var data, _this = this;

		data = {
			"ciHsmTokenUri" : uri,
    		"trashOutDateTime" : newDate
		}

		await this.props.actions.toTrash(this.props.base.serviceUrl, data, this.props.login.accessToken)
		.then((response) => {
			if (response !== null && response.contentType === "application/ccs.api.error+json;charset=UTF-8") {
				alert(response.json.message);
			}

			if (index === _this.state.checkedArr.length - 1) {
				_this.refreshList();
				_this.props.actions.setSelectedAll(false)
				_this.setState({"checkedArr": []})
			}
		})
	}

	putToTrash () {
		function addDays(date, days) {
		    const newDate = new Date(date);
		    newDate.setDate(date.getDate() + days);
		    return newDate.toISOString();
		}
		const todayDate = new Date();

		const days = 14;

		for (var i = 0; i < this.state.checkedArr.length; i += 1) {
			const newDate = addDays(todayDate, days);
    		this.putToTrashToken(this.props.tokenList.list[this.state.checkedArr[i]].uri, newDate, i)
		}
		// this.props.actions.setSelectedAll(false)
		// this.setState({"checkedArr": []})
		// this.refreshList()
	}

	async removeToken (uri, data, index) {
		var _this = this;
		await this.props.actions.removeToken(this.props.base.serviceUrl, uri, data, this.props.login.accessToken)
		.then((response) => {
			if (response !== null && response.contentType === "application/ccs.api.error+json;charset=UTF-8") {
				alert(response.json.techMessage);
				_this.setState({"breakLoop": true})
			}

			if (index === _this.state.checkedArr.length - 1) {
				_this.refreshList();
				_this.props.actions.setSelectedAll(false)
				_this.setState({"checkedArr": []})
			}
		})
	}

	async remove () {
		this.props.actions.setShowHsmSoPin(false);
		this.setState({"breakLoop": false})

		for (var i = 0; i < this.state.checkedArr.length; i += 1) {
			var jsonObject = {
				hsmSoPin: this.props.base.hsmSoPin
			};

			var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

			var data = {
				"ciHsmTokenUri": this.props.tokenList.list[this.state.checkedArr[i]].uri,
				"encryptedData": cipherResult.encryptedData,
				"secretKey": cipherResult.secretKey
			}

    		await this.removeToken(this.props.tokenList.list[this.state.checkedArr[i]].uri, data, i)

    		if (this.state.breakLoop === true) {
    			break;
    		}
   //  		if (!exit) {
			// 	break;
			// }
		}

		// this.props.actions.setSelectedAll(false)
		// this.setState({"checkedArr": []})
		// this.refreshList()
	}

	async reset (uri, data, index) {
		var _this = this;
		await this.props.actions.resetToken(this.props.base.serviceUrl, uri, data, this.props.login.accessToken)
		.then((response) => {
			if (response !== null && response.contentType === "application/ccs.api.error+json;charset=UTF-8") {
				alert(response.json.techMessage);
				_this.setState({"breakLoop": true})
			}

			if (index === _this.state.checkedArr.length - 1) {
				_this.refreshList();
				_this.props.actions.setSelectedAll(false)
				_this.setState({"checkedArr": []})
			}
		})
	}

	async resetToken () {
		this.props.actions.setShowHsmSoPinForReset(false);
		this.setState({"breakLoop": false})
		for (var i = 0; i < this.state.checkedArr.length; i += 1) {
			var jsonObject = {
				hsmSoPin: this.props.base.hsmSoPinForReset,
				newPin: Math.floor(10000000 + Math.random() * 90000000).toString(),
				newPuk: this.props.base.hsmSoPukForReset
			};

			var cipherResult = cipher(jsonObject, this.props.login.publicKeyTextual, new Date(Date.now() + this.props.login.timeDelta).toISOString().split('.')[0]+"Z");

			var data = {
				"ciHsmTokenUri": this.props.tokenList.list[this.state.checkedArr[i]].uri,
				"encryptedData": cipherResult.encryptedData,
				"secretKey": cipherResult.secretKey
			}

    		await this.reset(this.props.tokenList.list[this.state.checkedArr[i]].uri, data, i)

			if (this.state.breakLoop === true) {
    			break;
    		}
		}

		// this.props.actions.setSelectedAll(false)
		// this.setState({"checkedArr": []})
		// this.refreshList()
	}

	showSoPinPopup () {
		this.props.actions.setShowHsmSoPin(true);
	}

	changeTab () {
		this.props.actions.setActiveTab("trash")
	}

	showSoPinForResetPopup () {
		this.props.actions.setShowHsmSoPinForReset(true);
	}

	handleCheck (index) {
		var checked = this.state.checkedArr;

		if (!checked.includes(index)) {
			checked.push(index)

			if (checked.length === this.props.tokenList.list.length) {
				this.props.actions.setSelectedAll(true)
			}
		} else {
			const num = checked.indexOf(index);
			if (num > -1) {
			  checked.splice(num, 1);
			  this.props.actions.setSelectedAll(false)
			}
		}

		this.setState({"checkedArr": checked})

		console.log(checked)
	}

	handleSelectAll () {
		var i, arr = [];

		if (this.state.checkedArr.length === this.props.tokenList.list.length) {
			this.props.actions.setSelectedAll(false)
			this.setState({"checkedArr": []})
		} else {
			for (i = 0; i < this.props.tokenList.list.length; i += 1) {
				arr.push(i)
			}
			
			this.props.actions.setSelectedAll(true)
			this.setState({"checkedArr": arr})
		}
	}

	containElement (index) {
		var num = this.state.checkedArr.indexOf(index);

		if (num > -1) {
			return true;
		} else {
			return false;
		}
	}

	handleShowConfidants (index) {
		this.setState({selectedToken: index}, () => {this.setState({showConfidants: true})})
	}

	handleCloseConfidants () {
		this.setState({showConfidants: false})
	}

	prevPage ()	{
		if (this.props.page.number > 0) {
	        this.changePage(this.props.page.number - 1);
	    }
	}

	firstPage ()	{
	    this.changePage(0);
	}

	lastPage ()	{
	    this.changePage(this.numPages() - 1);
	}

	nextPage () {
		if (this.props.page.number < this.numPages()) {
	        this.changePage(this.props.page.number + 1);
	    }
	}

	changePage (page) {
	    if (page < 0) page = 0;
	    if (page >= this.numPages()) page = this.numPages() - 1;

	    this.props.actions.setSelectedAll(false)
		this.setState({"checkedArr": []})

	    var searchParams = {
            "uri": this.props.search.uri,
            "title": this.props.search.title,
            "status": this.props.search.status,
            "owner": {
                "id": this.props.search.ownerId,
                "commonName": this.props.search.ownerCommonName,
                "drfou": this.props.search.ownerDrfou,
                "edrpou": this.props.search.ownerEdrpou,
                "operator": this.props.search.ownerOperator
            },
            "confidant": {
                "id": this.props.search.confidantId,
                "commonName": this.props.search.confidantCommonName,
                "drfou": this.props.search.confidantDrfou,
                "edrpou": this.props.search.confidantEdrpou,
                "operator": this.props.search.confidantOperator
            },
            "createdDateTimeNotBefore": this.props.search.createdDateTimeNotBefore,
            "createdDateTimeNotAfter": this.props.search.createdDateTimeNotAfter
        }

	    var params = {
			page: page,
			size: this.props.page.size
		}

		var urlParams = this.props.actions.pageUrl(params)

		this.props.actions.getTokenList(this.props.base.serviceUrl, urlParams, searchParams, this.props.login.accessToken)
		.then((response) => {

			if (response.contentType !== undefined && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
                this.props.actions.setErrorResponse(response.json)
				this.setState({showErrorPopup: true})
                return
            }

			this.props.actions.setTokenList(response.content)
			this.props.actions.setALLPaginationParams(response.page)

			console.log(response)
		});
	}

	numPages () {
	    return Math.ceil(this.props.page.totalElements / this.props.page.size);
	}

	handleShowCountForm () {
		this.setState({showItemsCountForm: !this.state.showItemsCountForm})
	}

	refreshList () {
	    var searchParams = {
            "uri": this.props.search.uri,
            "title": this.props.search.title,
            "status": this.props.search.status,
            "owner": {
                "id": this.props.search.ownerId,
                "commonName": this.props.search.ownerCommonName,
                "drfou": this.props.search.ownerDrfou,
                "edrpou": this.props.search.ownerEdrpou,
                "operator": this.props.search.ownerOperator
            },
            "confidant": {
                "id": this.props.search.confidantId,
                "commonName": this.props.search.confidantCommonName,
                "drfou": this.props.search.confidantDrfou,
                "edrpou": this.props.search.confidantEdrpou,
                "operator": this.props.search.confidantOperator
            },
            "createdDateTimeNotBefore": this.props.search.createdDateTimeNotBefore,
            "createdDateTimeNotAfter": this.props.search.createdDateTimeNotAfter
        }

		var params = {
			page: this.props.page.number,
			size: this.props.page.size
		}

		var urlParams = this.props.actions.pageUrl(params)

		this.props.actions.getTokenList(this.props.base.serviceUrl, urlParams, searchParams, this.props.login.accessToken)
		.then((response) => {

			if (response.contentType !== undefined && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
                this.props.actions.setErrorResponse(response.json)
				this.setState({showErrorPopup: true})
                return
            }

			this.props.actions.setTokenList(response.content)
			this.props.actions.setALLPaginationParams(response.page)
		});
	}

	handleCountChanged (value) {
		var page;
		if (this.props.page.number < 1) page = 1;
	    if (this.props.page.number > this.numPages()) page = this.numPages();

	    this.props.actions.setSelectedAll(false)
		this.setState({"checkedArr": []})

	    var searchParams = {
            "uri": this.props.search.uri,
            "title": this.props.search.title,
            "status": this.props.search.status,
            "owner": {
                "id": this.props.search.ownerId,
                "commonName": this.props.search.ownerCommonName,
                "drfou": this.props.search.ownerDrfou,
                "edrpou": this.props.search.ownerEdrpou,
                "operator": this.props.search.ownerOperator
            },
            "confidant": {
                "id": this.props.search.confidantId,
                "commonName": this.props.search.confidantCommonName,
                "drfou": this.props.search.confidantDrfou,
                "edrpou": this.props.search.confidantEdrpou,
                "operator": this.props.search.confidantOperator
            },
            "createdDateTimeNotBefore": this.props.search.createdDateTimeNotBefore,
            "createdDateTimeNotAfter": this.props.search.createdDateTimeNotAfter
        }

		var params = {
			page: page - 1,
			size: value
		}
		this.props.actions.setPageSize(value);
		this.setState({showItemsCountForm: !this.state.showItemsCountForm});

		var urlParams = this.props.actions.pageUrl(params)

		this.props.actions.getTokenList(this.props.base.serviceUrl, urlParams, searchParams, this.props.login.accessToken)
		.then((response) => {

			if (response.contentType !== undefined && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
                this.props.actions.setErrorResponse(response.json)
				this.setState({showErrorPopup: true})
                return
            }

			this.props.actions.setTokenList(response.content)
			this.props.actions.setALLPaginationParams(response.page)

			console.log(response)
		});
	}

	getSelectedToken (index) {
		this.props.actions.setSelectedToken(index);
		this.props.actions.setTokenEnquire(this.props.tokenList.list[index])
	}

	handleSearchClick () {
		this.setState({searchOpen: !this.state.searchOpen})
		this.props.actions.changeSearchState(!this.state.searchOpen);
		this.setState({showSearchForm: false})
	}

	copyToClipboard (text, element) {
		var textArea, copy;

	    function isOS() {
	        return navigator.userAgent.match(/ipad|iphone/i);
	    }

	    function createTextArea(text) {
	        textArea = document.createElement('textArea');
	        textArea.value = text;
	        textArea.style.cssText = "position: absolute";
	        textArea.style.cssText = "top: -9999px";
	        document.body.appendChild(textArea);
	    }

	    function selectText() {
	        var range,
	            selection;

	        if (isOS()) {
	            range = document.createRange();
	            range.selectNodeContents(textArea);
	            selection = window.getSelection();
	            selection.removeAllRanges();
	            selection.addRange(range);
	            textArea.setSelectionRange(0, 999999);
	        } else {
	            textArea.select();
	        }
	    }

	    function copyToClipboard() {        
	        document.execCommand('copy');
	        document.body.removeChild(textArea);
	    }

	    copy = function(text) {
	        createTextArea(text);
	        selectText();
	        copyToClipboard();
	        document.getElementById(element).style.color = "green";
	        window.setTimeout(function () {
	        	document.getElementById(element).style.color = "#414549";
	        }, 1000)
		};

		copy(text);
	}

	render() {
		let itemsCountFormOpen = 'dropdown-menu'
		let useIEStyle = 'bottomright'
		let searchBarOpenState = 'searh-bar'
		let isEnabled = (this.state.checkedArr.length > 0) ? true : false;

		if (this.props.base.searchIsOpen) {
		    searchBarOpenState = 'searh-bar open'
		}

		if (this.state.showItemsCountForm) {
			itemsCountFormOpen = 'dropdown-menu show'
		}

		return (
			<div className="body-container">
				<div>
					<div className="buttons-top-div" style={{marginTop: "10px", paddingTop: "10px"}}>
						<button className="btn btn-info" style={{marginLeft: "10px", marginTop: "10xp", marginRight: "5px", marginBottom: "10px", float: "left"}} onClick={this.changeTab}>{i18next.t("trashTabLabel")}</button>
						<button className="btn btn-outline-info" style={{marginLeft: "5px", marginTop: "10xp", marginRight: "10px", marginBottom: "10px", float: "right"}} disabled={!isEnabled} onClick={this.showSoPinPopup}>{i18next.t("removeTokenLabel")}</button>
						<button className="btn btn-outline-info" style={{marginLeft: "5px", marginTop: "10xp", marginRight: "5px", marginBottom: "10px", float: "right"}} disabled={!isEnabled} onClick={this.showSoPinForResetPopup}>{i18next.t("resetTokenLabel")}</button>
						<button className="btn btn-outline-info" style={{marginLeft: "5px", marginTop: "10xp", marginRight: "5px", marginBottom: "10px", float: "right"}} disabled={!isEnabled} onClick={this.blocked}>{i18next.t("blockedLabel")}</button>
						<button className="btn btn-outline-info" style={{marginLeft: "5px", marginTop: "10xp", marginRight: "5px", marginBottom: "10px", float: "right"}} disabled={!isEnabled} onClick={this.activate}>{i18next.t("activateLabal")}</button>
						<button className="btn btn-outline-info" style={{marginLeft: "5px", marginTop: "10xp", marginRight: "5px", marginBottom: "10px", float: "right"}} disabled={!isEnabled} onClick={this.putToTrash}>{i18next.t("toTrash")}</button>
					</div>
					<div id="search-bar" className={searchBarOpenState}>
						<div style={{"cursor": "pointer", marginTop: "-5px"}}>
							<div style={{float: "right", marginRight: "15px"}} onClick={this.handleSearchClick}>
								{
									this.state.searchOpen
									? <span>{i18next.t("searchLabel")}<i className="fa fa-chevron-up" style={{paddingLeft: "5px"}}></i></span>
									: <span>{i18next.t("searchLabel")}<i className="fa fa-chevron-down" style={{paddingLeft: "5px"}}></i></span>
								}
							</div>
						</div>
						<div style={{paddingTop: "20px"}}>
							{
								this.state.showSearchForm
								? <TokenSearchContainer />
								: null
							}
						</div>
					</div>
					{
						this.props.tokenList.list !== undefined && this.props.tokenList.list.length > 0
						? <div className="table-holder">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 table_div" style={{paddingLeft: "0px", paddingRight: "0px", height: "100%"}}>
								<div className="row fixed_header_div">

									<div className="col-xs-1 col-sm-1 col-md-1 ccol-lg-1 no-left-right-margin abc-checkbox" style={{ alignSelf: "top"}}>
										<div className="checkbox" style={{borderWidth: "0px", borderStyle: "none", padding: "0px"}}>
					                        <input id="checkbox1" onChange={this.handleSelectAll} checked={this.props.tokenList.selectedAll} type="checkbox" />
					                        <label htmlFor="checkbox1">
					                        </label>
					                    </div>
									</div>

									<div className="col-xs-11 col-sm-6 col-md-3 ccol-lg-2">{i18next.t("userNameSurnameLabel")}</div>
									<div className="col-xs-8 col-sm-4 col-md-3 ccol-lg-3 d-none d-md-block">{i18next.t("uniqueIdLabel")}</div>
									<div className="col-xs-1 col-sm-5 col-md-5 col-lg-2 d-none d-sm-block">{i18next.t("tokenName")}</div>
									<div className="col-xs-1 col-sm-5 col-md-5 ccol-lg-2 d-none d-lg-block">{i18next.t("tokenUri")}</div>
									<div className="col-xs-1 col-sm-4 col-md-4 col-lg-1 d-none d-lg-block">{i18next.t("tokenStatus")}</div>
									<div className="col-xs-3 col-sm-2 col-md-3 col-lg-1 d-none d-lg-block" style={{paddingLeft: "5px", paddingRight: "5px"}}>{i18next.t("specifications")}</div>
								</div>
								<div className="fixed_div">
									{
										this.props.tokenList.list.map((token, index) => {
											var activeClass = ""
											if (index % 2) {
												activeClass = "row odd"
											} else {
												activeClass = "row even"
											}
											return (<div key={index} className={token.status !== "ACTIVE" ? "row blocked" : activeClass} style={{marginTop: "5px"}}>
														<div className="col-xs-1 col-sm-1 col-md-1 ccol-lg-1 no-left-right-margin abc-checkbox" style={{textAlign: "center", paddingRight: "0px", margin: "auto"}}>
						                            		<div className="checkbox">
										                        <input id={index} checked={this.containElement(index)} onChange={this.handleCheck.bind(this, index)} type="checkbox" />
										                        <label htmlFor={index}>
										                        </label>
										                    </div>
											    		</div>

														<div className="col-xs-11 col-sm-6 col-md-3 ccol-lg-2 cursor-pointer row">
															<div className="ccol-11" onClick={this.getSelectedToken.bind(this, index)} style={{paddingLeft: "0px", paddingRight: "0px", margin: "auto"}}>{token.owner.commonName}</div><i className="fa fa-copy ccol-1" id={"commonName" + index} onClick={this.copyToClipboard.bind(this, token.owner.commonName, "commonName" + index)} style={{margin: "auto", paddingLeft: "0px", paddingRight: "0px"}}></i>
														</div>

														<div className="col-xs-8 col-sm-4 col-md-3 ccol-lg-3 cursor-pointer row d-none d-md-block">
															<div className="ccol-12 row">
																<div className="ccol-11" style={{wordBreak: "break-all", paddingLeft: "0px", paddingRight: "0px", margin: "auto"}} onClick={this.getSelectedToken.bind(this, index)}>{token.owner.id}</div><i className="fa fa-copy ccol-1" id={"idName" + index} onClick={this.copyToClipboard.bind(this, token.owner.id, "idName" + index)} style={{margin: "auto", paddingLeft: "0px", paddingRight: "0px"}}></i>
															</div>
														</div>
											    		<div className="col-xs-1 col-sm-5 col-md-5 col-lg-2 d-none d-sm-block cursor-pointer" onClick={this.getSelectedToken.bind(this, index)} style={{textAlign: "center", margin: "auto"}}>
											    			<div>{token.title}</div>
											    		</div>
											    		<div className="col-xs-1 col-sm-5 col-md-5 ccol-lg-2 d-none d-lg-block cursor-pointer row">
											    			<div className="ccol-12 row">
																<div className="ccol-11" style={{paddingLeft: "0px", paddingRight: "0px", margin: "auto"}} onClick={this.getSelectedToken.bind(this, index)}>{token.uri}</div><div className="ccol-1"><i className="fa fa-copy" id={"uriName" + index} onClick={this.copyToClipboard.bind(this, token.uri, "uriName" + index)} style={{margin: "auto", paddingLeft: "0px", paddingRight: "0px"}}></i></div>
											    			</div>
											    		</div>
											    		<div className="col-xs-1 col-sm-4 col-md-4 col-lg-1 d-none d-lg-block cursor-pointer" onClick={this.getSelectedToken.bind(this, index)} style={{textAlign: "center", margin: "auto"}}>{i18next.t(token.status)}</div>
											    		<div className="col-xs-3 col-sm-2 col-md-3 col-lg-1 d-none d-lg-block" style={{textAlign: "center", margin: "auto"}}>
											    			{
											    				token.confidants.length > 0
											    				? <i className="fa fa-user" style={{cursor: "pointer"}} onClick={this.handleShowConfidants.bind(this, index)}></i>
											    				: null
											    			}
											    		</div>
											</div>)
										})
									}
				            	</div>
							</div>
						</div> 
						: null
					}
				</div>
				{
					this.numPages() === 0
					? null
					: <div className={useIEStyle}>
						<div className="row">
							<div className="btn-group dropup" style={{minWidth: "5rem"}}>
								<button type="button" onClick={this.handleShowCountForm} className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showItemsCountForm}>
									{
										this.props.page.size
									}
								</button>
								<div className={itemsCountFormOpen} style={{minWidth: "5rem"}}>
									{
										this.state.elementsCountArray.map((value, index) => {
											return (<span key={index} className="dropdown-item" style={{cursor: "pointer"}} onClick={this.handleCountChanged.bind(this, value)}>{value}</span>)
										})
									}
								</div>
							</div>

							<div className="row" style={{width: "255px", textAlign: "center"}}>
								<div className={this.props.page.number === 0 ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} onClick={this.firstPage}>
									<img className="img" style={{marginTop: "5px"}} src={first} width="20" height="26" alt={first}/>
								</div>
								<div className={this.props.page.number === 0 ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingTop: "5px"}} onClick={this.prevPage}>
									<i className="fa fa-chevron-left"></i>
								</div>
								<div className="col-5" style={{paddingTop: "5px", textAlign: "center", paddingLeft: "0px", paddingRight: "0px"}}>
									{
										this.props.page.number + 1
									}
									<span style={{paddingTop: "5px"}}> / </span>
									{
										this.numPages()
									}
								</div>
								
								<div className={this.props.page.number === (this.numPages() - 1) ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingTop: "5px", paddingLeft: "0px"}} onClick={this.nextPage}>
									<i className="fa fa-chevron-right"></i>
								</div>
								<div className={this.props.page.number === (this.numPages() - 1) ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingLeft: "0px"}} onClick={this.lastPage}>
									<img className="img" style={{marginTop: "5px"}} src={last} width="20" height="26" alt={last}/>
								</div>
							</div>
						</div>
					</div>
				}
				{
                	this.state.showConfidants
                	? <ConfidantsPopup close={this.handleCloseConfidants.bind(this)} confidants={this.props.tokenList.list[this.state.selectedToken].confidants} />
                	: null
                }
                {
                	this.props.base.showHsmSoPin
                	? <HsmSoPinPopup removeToken={this.remove} />
                	: null
                }
                {
                	this.props.base.showHsmSoPinForReset
                	? <HsmSoPinForResetPopup resetToken={this.resetToken} />
                	: null
                }
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        tokenList: state.tokenListReducer,
        page: state.paginationReducer,
        search: state.tokenSearchReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setPageSize, pageUrl, getTokenList,
    	setErrorResponse, setTokenList, setALLPaginationParams,
    	setSelectedAll, setSelectedToken, setTokenEnquire, changeSearchState,
    	blockToken, unblockToken, removeToken,
    	setShowHsmSoPin, setHsmSoPin, setShowHsmSoPinForReset, resetToken,
    	setActiveTab, toTrash
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);