const baseState = {
    uri: null,
    operator : null,
    ownerId: null,
    ownerCommonName: null,
    ownerDrfou: null,
    ownerEdrpou: null,
    ownerOperator: null,
    trashInDateTimeNotBefore: null,
    trashInDateTimeNotAfter: null,
    trashOutDateTimeNotBefore: null,
    trashOutDateTimeNotAfter: null
}

const tokenInTrashSearchReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_TOKEN_IN_TRASH_SEARCH_URI": {
			return {
				...state,
				uri: payload
			}
		}

		case "SET_TOKEN_IN_TRASH_SEARCH_OPERATOR": {
			return {
				...state,
				operator: payload
			}
		}

		case "SET_TOKEN_IN_TRASH_SEARCH_TRASH_IN_DATE_NOT_BEFORE": {
			return {
				...state,
				trashInDateTimeNotBefore: payload
			}
		}

		case "SET_TOKEN_IN_TRASH_SEARCH_TRASH_IN_DATE_NOT_AFTER": {
			return {
				...state,
				trashInDateTimeNotAfter: payload
			}
		}

		case "SET_TOKEN_IN_TRASH_SEARCH_TRASH_OUT_DATE_NOT_BEFORE": {
			return {
				...state,
				trashOutDateTimeNotBefore: payload
			}
		}

		case "SET_TOKEN_IN_TRASH_SEARCH_TRASH_OUT_DATE_NOT_AFTER": {
			return {
				...state,
				trashOutDateTimeNotAfter: payload
			}
		}

		case "SET_TOKEN_IN_TRASH_SEARCH_OWNER_ID": {
			return {
				...state,
				ownerId: payload
			}
		}

		case "SET_TOKEN_IN_TRASH_SEARCH_OWNER_COMMON_NAME": {
			return {
				...state,
				ownerCommonName: payload
			}
		}

		case "SET_TOKEN_IN_TRASH_SEARCH_OWNER_DRFOU": {
			return {
				...state,
				ownerDrfou: payload
			}
		}

		case "SET_TOKEN_IN_TRASH_SEARCH_OWNER_EDRPOU": {
			return {
				...state,
				ownerEdrpou: payload
			}
		}

		case "SET_TOKEN_IN_TRASH_SEARCH_OWNER_OPERATOR": {
			return {
				...state,
				ownerOperator: payload
			}
		}

		case "SET_TOKEN_IN_TRASH_SEARCH_OWNER_PARAMS": {
			return {
				...state,
				ownerParams: payload
			}
		}

		case "CLEAR_ALL_TOKEN_IN_TRASH_SEARCH_FIELDS": {
			return {
				...state,
				uri: null,
			    operator : null,
			    ownerId: null,
			    ownerCommonName: null,
			    ownerDrfou: null,
			    ownerEdrpou: null,
			    ownerOperator: null,
			    trashInDateTimeNotBefore: null,
			    trashInDateTimeNotAfter: null,
			    trashOutDateTimeNotBefore: null,
			    trashOutDateTimeNotAfter: null
			}
		}

        default:
            return state;
    }
}

export default tokenInTrashSearchReducer