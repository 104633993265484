const baseState = {
    isLoggedIn: false,
    login: "",
    password: "",
    accessToken: null,
    loggedInUserData: null,
    loggedInUserName: null,
    loggedInUserId: null,
    userData: null,
    publicKeyTextual: null,
    currentUtcDateTime: null,
    twoFactorEnabled: false,
    twoFactorCode: "",
    is2fEnabled: false,
    update2fSettingsGrant: false,
    certificateIssuingGrant: false,
    timeDelta: 0
}

const loginReducer = (state = baseState, { type, payload }) => {

    switch (type) {
    	case "SET_IS_LOGGED_IN": {
			return {
				...state,
				isLoggedIn: payload
			}
		}

		case "SET_LOGIN_INPUT": {
			return {
				...state,
				login: payload
			}
		}

		case "SET_PASSWORD_INPUT": {
			return {
				...state,
				password: payload
			}
		}

		case "SET_TOKEN": {
			return {
				...state,
				accessToken: payload
			}
		}

		case "CLEAR_TOKEN": {
			return {
				...state,
				accessToken: null
			}
		}

		case "SET_LOGGED_IN_USER_DATA": {
			return {
				...state,
				loggedInUserData: payload,
				loggedInUserName: payload.userName,
				loggedInUserId: payload.firstName
			}
		}

		case "SET_ALL_USER_FIELDS": {
			return {
				...state,
				userData: payload
			}
		}

		case "CLEAR_LOGGED_IN_USER_DATA": {
			return {
				...state,
				loggedInUserData: null,
				loggedInUserName: null,
				loggedInUserId: null,
				twoFactorEnabled: false
			}
		}

		case "SET_PUBLIC_KEY_CURRENT_UTC_DATE_TIME": {
			return {
				...state,
				publicKeyTextual: payload.publicKeyTextual,
				currentUtcDateTime: payload.currentUtcDateTime
			}
		}

		case "CLEAR_PUBLIC_KEY_CURRENT_UTC_DATE_TIME": {
			return {
				...state,
				publicKeyTextual: null,
				currentUtcDateTime: null
			}
		}

		case "SET_TWO_FACTOR_ENABLED": {
			return {
				...state,
				twoFactorEnabled: payload
			}
		}

		case "CLEAR_TWO_FACTOR_ENABLED": {
			return {
				...state,
				twoFactorEnabled: false
			}
		}

		case "SET_TWO_FACTOR_CODE": {
			return {
				...state,
				twoFactorCode: payload
			}
		}

		case "CLEAR_TWO_FACTOR_CODE": {
			return {
				...state,
				twoFactorCode: ""
			}
		}

		case "SET_UPDATE_2F_SETTINGS_GRANT": {
			return {
				...state,
				update2fSettingsGrant: payload
			}
		}

		case "SET_IS_2F_ENABLED": {
			return {
				...state,
				is2fEnabled: payload
			}
		}

		case "SET_CERTIFICATE_ISSUING_GRANT": {
            return {
                ...state,
                certificateIssuingGrant: payload
            }
        }

        case "SET_TIME_DELTA": {
			return {
				...state,
				timeDelta: payload
			}
		}

        default:
            return state;
    }
}

export default loginReducer