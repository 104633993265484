import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setHsmSoPinForReset, setHsmSoPukForReset, setShowHsmSoPinForReset } from '../actions/localStates'

class HsmSoPinForResetPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
        confirmPuk: "",
        errorMessagePuk: "",
        confirmedPukNotEqual: false,
        errorMessageForConfirmPuk: "",
        errorMessageHsmSoPin: ""
      }

      this.onHsmSoPinChanged = this.onHsmSoPinChanged.bind(this);

      this.handle2FKeyUp = this.handle2FKeyUp.bind(this, 'twoFactorInput');

      this.closeHsmSoPin = this.closeHsmSoPin.bind(this);

      this.submit = this.submit.bind(this);

      this.onHsmSoPukChanged = this.onHsmSoPukChanged.bind(this);

      this.handleConfirmPukInput = this.handleConfirmPukInput.bind(this);
  }

  componentDidMount() {
      this.props.actions.setHsmSoPinForReset("")
      this.props.actions.setHsmSoPukForReset("")
      var element = document.getElementById("2f");
      element.focus();
      document.addEventListener('keyup', this.handle2FKeyUp);
  }

  componentWillUnmount() {
      document.removeEventListener('keyup', this.handle2FKeyUp);
  }

  handle2FKeyUp(refName, e) {
      if (e.keyCode === 13 && refName === "twoFactorInput") {
          document.getElementById("submitPin").click();
          document.activeElement.blur();
      }
  }

  onHsmSoPinChanged (e) {
    this.props.actions.setHsmSoPinForReset(e.target.value)
    e.target.value.length > 0 && e.target.value.length < 8
      ? this.setState({errorMessageHsmSoPin: "pinMinLength"})
      : e.target.value.length === 0
      ? this.setState({pinIsEmpty: true})
      : this.setState({errorMessageHsmSoPin: ""})
  }

  onHsmSoPukChanged (e) {
    var _this = this;
    var value = e.target.value
    this.props.actions.setHsmSoPukForReset(e.target.value)
    e.target.value.length > 0 && e.target.value.length < 4
      ? this.setState({errorMessagePuk: "pukMinLength"}, () => {
        if (_this.state.confirmPuk.length > 0) {
          _this.state.confirmPuk === value
          ? _this.setState({confirmedPukNotEqual: false}, () => {
            _this.setState({errorMessageForConfirmPuk: ""})
            })
          : _this.setState({confirmedPukNotEqual: true}, () => {
            _this.setState({errorMessageForConfirmPuk: "pukNotEqual"})
            })
        }
      })
      : this.setState({newIsEmpty: false}, () => {
        this.setState({errorMessagePuk: ""}, () => {
          if (_this.state.confirmPuk.length > 0) {
            _this.state.confirmPuk === value
            ? _this.setState({confirmedPukNotEqual: false}, () => {
              _this.setState({errorMessageForConfirmPuk: ""})
              })
            : _this.setState({confirmedPukNotEqual: true}, () => {
              _this.setState({errorMessageForConfirmPuk: "pukNotEqual"})
              })
          }
      })
    })
  }

  submit () {
    this.props.resetToken();
  }

  closeHsmSoPin () {
    this.props.actions.setShowHsmSoPinForReset(false)
  }

  hoverPin() {
        this.setState({showPin: true})
  }

  outPin() {
      this.setState({showPin: false})
  }

  hoverPuk() {
        this.setState({showPuk: true})
  }

  outPuk() {
      this.setState({showPuk: false})
  }

  hoverConfirmPuk() {
        this.setState({showConfirmPuk: true})
  }

  outConfirmPuk() {
      this.setState({showConfirmPuk: false})
  }

  handleConfirmPukInput (e) {
    this.setState({confirmPuk: e.target.value})
    this.props.base.hsmSoPukForReset === e.target.value
    ? this.setState({confirmedPukNotEqual: false}, () => {
      this.setState({errorMessageForConfirmPuk: ""})
      })
    : this.setState({confirmedPukNotEqual: true}, () => {
      this.setState({errorMessageForConfirmPuk: "pukNotEqual"})
      })
  }

  render() {
    let isEnabled = this.props.base.hsmSoPinForReset.length >= 8 && this.props.base.hsmSoPukForReset.length >= 4 && this.state.confirmPuk.length >= 4 && !this.state.confirmedPukNotEqual
    ? true
    : false

      return (
          <div className='popup'>
              <div className={"popup_inner_user_token_pin"}>
                  <div className="col-12">
                  <h4 style={{paddingLeft: "15px", paddingTop: "30px"}}>{i18next.t("resetTokenLabel")}</h4>
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingTop: "0px", paddingBottom: "0px"}}>{i18next.t("hsmSoPinTitle")}</label>
                          <div className="col-12 input-group">
                              <input id="2f" className={(this.state.errorMessageHsmSoPin.length > 0) ? "form-control is-invalid" : "form-control"} value={this.props.base.hsmSoPinForReset} type={this.state.showPin ? "input" : "password"} onChange={this.onHsmSoPinChanged} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverPin.bind(this)} onMouseOut={this.outPin.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessageHsmSoPin.length > 0
                              ? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessageHsmSoPin)}</span>
                              : null
                          }
                      </div>
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af", paddingTop: "0px", paddingBottom: "0px"}}>{i18next.t("puk")}</label>
                          <div className="col-12 input-group">
                              <input className={(this.state.errorMessagePuk.length > 0) ? "form-control is-invalid" : "form-control"} value={this.props.base.hsmSoPukForReset} type={this.state.showPuk ? "input" : "password"} onChange={this.onHsmSoPukChanged} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverPuk.bind(this)} onMouseOut={this.outPuk.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessagePuk.length > 0
                              ? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessagePuk)}</span>
                              : null
                          }
                      </div>
                      <div>
                          <label className="col-12 col-form-label" style={{fontSize: "15px", color: "#b5b1af"}}>{i18next.t("confirmPuk")}</label>
                          <div className="col-12 input-group">
                              <input className={this.state.confirmedPukNotEqual ? "form-control is-invalid" : "form-control"} value={this.state.confirmPuk} type={this.state.showConfirmPuk ? "input" : "password"} onChange={this.handleConfirmPukInput} />
                              <div className="input-group-append">
                                  <div className="input-group-text" onMouseOver={this.hoverConfirmPuk.bind(this)} onMouseOut={this.outConfirmPuk.bind(this)}><i className="fa fa-eye"></i></div>
                              </div>
                          </div>
                          {
                              this.state.errorMessageForConfirmPuk.length > 0
                              ? <span style={{color: "red", paddingLeft: "15px"}}>{i18next.t(this.state.errorMessageForConfirmPuk)}</span>
                              : null
                          }
                      </div>
                      <div style={{float: "right", marginRight: "15px", paddingTop: "10px"}}>
                          <button className="btn btn-outline-info" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.closeHsmSoPin}>{i18next.t("cancelLabel")}</button>
                      </div>
                      <div style={{float: "right", marginRight: "5px", paddingTop: "10px"}}>
                          <button className="btn btn-info" id="submitPin" style={{marginLeft: "5px", marginBottom: "30px"}} disabled={!isEnabled} onClick={this.submit}>{i18next.t("okLabel")}</button>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        selectedClient: state.clientReducer,
        login: state.loginReducer,
        userReducer: state.userReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      setHsmSoPinForReset, setHsmSoPukForReset, setShowHsmSoPinForReset
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HsmSoPinForResetPopup);