import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getTokenInTrashList } from "../actions/api"
import { setErrorResponse, setTokenInTrashList, setALLPaginationParamsForTrash } from "../actions/localStates"

import TrashTab from '../components/TrashTab';

class TrashContainer extends Component {

	componentDidMount() {

		var searchParams = {
            "uri": this.props.search.uri,
            "operator": this.props.search.operator,
            "status": this.props.search.status,
            "owner": {
                "id": this.props.search.ownerId,
                "commonName": this.props.search.ownerCommonName,
                "drfou": this.props.search.ownerDrfou,
                "edrpou": this.props.search.ownerEdrpou,
                "operator": this.props.search.ownerOperator
            },
            "trashInDateTimeNotBefore": this.props.search.trashInDateTimeNotBefore,
            "trashInDateTimeNotAfter": this.props.search.trashInDateTimeNotAfter,
            "trashOutDateTimeNotBefore": this.props.search.trashOutDateTimeNotBefore,
            "trashOutDateTimeNotAfter": this.props.search.trashOutDateTimeNotAfter
        }

		var urlParams = "";

		this.props.actions.getTokenInTrashList(this.props.base.serviceUrl, urlParams, searchParams, this.props.login.accessToken)
		.then((response) => {

			if (response.contentType !== undefined && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
                this.props.actions.setErrorResponse(response.json)
				this.setState({showErrorPopup: true})
                return
            }

			this.props.actions.setTokenInTrashList(response.content)
			this.props.actions.setALLPaginationParamsForTrash(response.page)

			console.log(response)			
		});
	}

	render() {
		return (
			<TrashTab />
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        search: state.tokenInTrashSearchReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    getTokenInTrashList, setErrorResponse, setTokenInTrashList, setALLPaginationParamsForTrash
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TrashContainer);