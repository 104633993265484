import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { restoreFromTrash, getTokenInTrashList } from '../actions/api'
import { setActiveTab, changeSearchInTrashState, setSelectedAllTrash, setTokenInTrashList, setALLPaginationParamsForTrash, setPageNumberTrash,
			setPageSizeTrash, setTotalElementsTrash, setTotalPagesTrash, setSelectedToken, setTokenEnquire } from '../actions/localStates'
import { formatDate, pageUrl } from '../actions/helpers'

import TokenInTrashSearchContainer from '../containers/TokenInTrashSearchContainer'

import first from '../img/first.svg'
import last from '../img/last.svg'

let searchBar;

class TrashTab extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	searchOpen: false,
            showSearchForm: false,
            elementsCountArray: [10, 20, 50],
	    	showItemsCountForm: false,
	    	current_page: 1,
	    	checkedArr: [],
			records_per_page: this.props.page.size
	    }

		this.changeTab = this.changeTab.bind(this);

		this.prevPage = this.prevPage.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.firstPage = this.firstPage.bind(this);
		this.lastPage = this.lastPage.bind(this);
		this.changePage = this.changePage.bind(this);
		this.numPages = this.numPages.bind(this);

		this.handleSearchClick = this.handleSearchClick.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);

		this.restore = this.restore.bind(this);
		this.handleShowCountForm = this.handleShowCountForm.bind(this);

		// this.copyToClipboard = this.copyToClipboard.bind(this);
	}

	componentDidMount() {
		var _this = this;
		_this.props.actions.changeSearchInTrashState(false)
		searchBar = document.getElementById("search-in-trash-bar");
		searchBar.addEventListener( 'webkitTransitionEnd', function( event ) { _this.setState({showSearchForm: _this.state.searchOpen}) }, false );
	}

	changeTab () {
		this.props.actions.setActiveTab("body")
	}

	handleShowCountForm () {
		this.setState({showItemsCountForm: !this.state.showItemsCountForm})
	}

	handleSearchClick () {
		this.setState({searchOpen: !this.state.searchOpen})
		this.props.actions.changeSearchInTrashState(!this.state.searchOpen);
		this.setState({showSearchForm: false})
	}

	numPages () {
	    return Math.ceil(this.props.page.totalElements / this.props.page.size);
	}

	prevPage ()	{
		if (this.props.page.number > 0) {
	        this.changePage(this.props.page.number - 1);
	    }
	}

	firstPage ()	{
	    this.changePage(0);
	}

	lastPage ()	{
	    this.changePage(this.numPages() - 1);
	}

	nextPage () {
		if (this.props.page.number < this.numPages()) {
	        this.changePage(this.props.page.number + 1);
	    }
	}

	changePage (page) {
	    if (page < 0) page = 0;
	    if (page >= this.numPages()) page = this.numPages() - 1;

	    this.props.actions.setSelectedAllTrash(false)
		this.setState({"checkedArr": []})

	    var searchParams = {
            "uri": this.props.search.uri,
            "operator": this.props.search.operator,
            "owner": {
                "id": this.props.search.ownerId,
                "commonName": this.props.search.ownerCommonName,
                "drfou": this.props.search.ownerDrfou,
                "edrpou": this.props.search.ownerEdrpou,
                "operator": this.props.search.ownerOperator
            },
            "trashInDateTimeNotBefore": this.props.search.trashInDateTimeNotBefore,
		    "trashInDateTimeNotAfter": this.props.search.trashInDateTimeNotAfter,
		    "trashOutDateTimeNotBefore": this.props.search.trashOutDateTimeNotBefore,
		    "trashOutDateTimeNotAfter": this.props.search.trashOutDateTimeNotAfter
        }

	    var params = {
			page: page,
			size: this.props.page.size
		}

		var urlParams = this.props.actions.pageUrl(params)

		this.props.actions.getTokenInTrashList(this.props.base.serviceUrl, urlParams, searchParams, this.props.login.accessToken)
		.then((response) => {

			if (response.contentType !== undefined && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
                this.props.actions.setErrorResponse(response.json)
				this.setState({showErrorPopup: true})
                return
            }

			this.props.actions.setTokenInTrashList(response.content)
			this.props.actions.setALLPaginationParamsForTrash(response.page)

			console.log(response)
		});
	}

	handleCheck (index) {
		var checked = this.state.checkedArr;

		if (!checked.includes(index)) {
			checked.push(index)

			if (checked.length === this.props.tokenList.list.length) {
				this.props.actions.setSelectedAllTrash(true)
			}
		} else {
			const num = checked.indexOf(index);
			if (num > -1) {
			  checked.splice(num, 1);
			  this.props.actions.setSelectedAllTrash(false)
			}
		}

		this.setState({"checkedArr": checked})

		console.log(checked)
	}

	handleSelectAll () {
		var i, arr = [];

		if (this.state.checkedArr.length === this.props.tokenList.list.length) {
			this.props.actions.setSelectedAllTrash(false)
			this.setState({"checkedArr": []})
		} else {
			for (i = 0; i < this.props.tokenList.list.length; i += 1) {
				arr.push(i)
			}
			
			this.props.actions.setSelectedAllTrash(true)
			this.setState({"checkedArr": arr})
		}
	}

	containElement (index) {
		var num = this.state.checkedArr.indexOf(index);

		if (num > -1) {
			return true;
		} else {
			return false;
		}
	}

	getSelectedToken (index) {
		return;
		// this.props.actions.setSelectedToken(index);
		// this.props.actions.setTokenEnquire(this.props.tokenList.list[index].vtoken)
	}

	handleCountChanged (value) {
		var page;
		if (this.props.page.number < 1) page = 1;
	    if (this.props.page.number > this.numPages()) page = this.numPages();

	    this.props.actions.setSelectedAllTrash(false)
		this.setState({"checkedArr": []})

	    var searchParams = {
            "uri": this.props.search.uri,
            "operator": this.props.search.operator,
            "owner": {
                "id": this.props.search.ownerId,
                "commonName": this.props.search.ownerCommonName,
                "drfou": this.props.search.ownerDrfou,
                "edrpou": this.props.search.ownerEdrpou,
                "operator": this.props.search.ownerOperator
            },
            "trashInDateTimeNotBefore": this.props.search.trashInDateTimeNotBefore,
		    "trashInDateTimeNotAfter": this.props.search.trashInDateTimeNotAfter,
		    "trashOutDateTimeNotBefore": this.props.search.trashOutDateTimeNotBefore,
		    "trashOutDateTimeNotAfter": this.props.search.trashOutDateTimeNotAfter
        }

		var params = {
			page: page - 1,
			size: value
		}
		this.props.actions.setPageSizeTrash(value);
		this.setState({showItemsCountForm: !this.state.showItemsCountForm});

		var urlParams = this.props.actions.pageUrl(params)

		this.props.actions.getTokenInTrashList(this.props.base.serviceUrl, urlParams, searchParams, this.props.login.accessToken)
		.then((response) => {

			if (response.contentType !== undefined && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
                this.props.actions.setErrorResponse(response.json)
				this.setState({showErrorPopup: true})
                return
            }

			this.props.actions.setTokenInTrashList(response.content)
			this.props.actions.setALLPaginationParamsForTrash(response.page)

			console.log(response)
		});
	}

	copyToClipboard (text, element) {
		var textArea, copy;

	    function isOS() {
	        return navigator.userAgent.match(/ipad|iphone/i);
	    }

	    function createTextArea(text) {
	        textArea = document.createElement('textArea');
	        textArea.value = text;
	        textArea.style.cssText = "position: absolute";
	        textArea.style.cssText = "top: -9999px";
	        document.body.appendChild(textArea);
	    }

	    function selectText() {
	        var range,
	            selection;

	        if (isOS()) {
	            range = document.createRange();
	            range.selectNodeContents(textArea);
	            selection = window.getSelection();
	            selection.removeAllRanges();
	            selection.addRange(range);
	            textArea.setSelectionRange(0, 999999);
	        } else {
	            textArea.select();
	        }
	    }

	    function copyToClipboard() {        
	        document.execCommand('copy');
	        document.body.removeChild(textArea);
	    }

	    copy = function(text) {
	        createTextArea(text);
	        selectText();
	        copyToClipboard();
	        document.getElementById(element).style.color = "green";
	        window.setTimeout(function () {
	        	document.getElementById(element).style.color = "#414549";
	        }, 1000)
		};

		copy(text);
	}

	async restoreToken (uri, index) {
		var data, _this = this;

		data = {
			"ciHsmTokenUri" : uri
		}

		await this.props.actions.restoreFromTrash(this.props.base.serviceUrl, data, this.props.login.accessToken)
		.then((response) => {
			if (response !== null && response.contentType === "application/ccs.api.error+json;charset=UTF-8") {
				alert(response.json.message);
			}

			if (index === _this.state.checkedArr.length - 1) {
				_this.refreshList();
				_this.props.actions.setSelectedAllTrash(false)
				_this.setState({"checkedArr": []})
			}
		})
	}

	restore () {
		// var _this = this;
		for (var i = 0; i < this.state.checkedArr.length; i += 1) {
    		this.restoreToken(this.props.tokenList.list[this.state.checkedArr[i]].vtoken.uri, i)
		}
		
	}

	refreshList () {
	    var searchParams = {
            "uri": this.props.search.uri,
            "operator": this.props.search.operator,
            "status": this.props.search.status,
            "owner": {
                "id": this.props.search.ownerId,
                "commonName": this.props.search.ownerCommonName,
                "drfou": this.props.search.ownerDrfou,
                "edrpou": this.props.search.ownerEdrpou,
                "operator": this.props.search.ownerOperator
            },
            "trashInDateTimeNotBefore": this.props.search.trashInDateTimeNotBefore,
		    "trashInDateTimeNotAfter": this.props.search.trashInDateTimeNotAfter,
		    "trashOutDateTimeNotBefore": this.props.search.trashOutDateTimeNotBefore,
		    "trashOutDateTimeNotAfter": this.props.search.trashOutDateTimeNotAfter
        }

		var params = {
			page: this.props.page.number,
			size: this.props.page.size
		}

		var urlParams = this.props.actions.pageUrl(params)

		this.props.actions.getTokenInTrashList(this.props.base.serviceUrl, urlParams, searchParams, this.props.login.accessToken)
		.then((response) => {

			if (response.contentType !== undefined && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
                this.props.actions.setErrorResponse(response.json)
				this.setState({showErrorPopup: true})
                return
            }

			this.props.actions.setTokenInTrashList(response.content)
			this.props.actions.setALLPaginationParamsForTrash(response.page)
		});
	}

	render() {
		let searchBarOpenState = 'searh-bar'
		let useIEStyle = 'bottomright'
		let itemsCountFormOpen = 'dropdown-menu'
		let isEnabled = (this.state.checkedArr.length > 0) ? true : false;

		if (this.props.base.searchInTrashIsOpen) {
		    searchBarOpenState = 'searh-bar open'
		}

		if (this.state.showItemsCountForm) {
			itemsCountFormOpen = 'dropdown-menu show'
		}

		return (
			<div className="body-container">
				<div>
					<div className="buttons-top-div" style={{marginTop: "10px", paddingTop: "10px"}}>
						<button className="btn btn-info" style={{marginLeft: "10px", marginTop: "10xp", marginRight: "5px", marginBottom: "10px", float: "left"}} onClick={this.changeTab}>{i18next.t("tokenTabLabel")}</button>
						<button className="btn btn-outline-info" style={{marginLeft: "5px", marginTop: "10xp", marginRight: "10px", marginBottom: "10px", float: "right"}} disabled={!isEnabled} onClick={this.restore}>{i18next.t("restoreTokenLabel")}</button>
					</div>
					<div id="search-in-trash-bar" className={searchBarOpenState}>
						<div style={{"cursor": "pointer", marginTop: "-5px"}}>
							<div style={{float: "right", marginRight: "15px"}} onClick={this.handleSearchClick}>
								{
									this.state.searchOpen
									? <span>{i18next.t("searchLabel")}<i className="fa fa-chevron-up" style={{paddingLeft: "5px"}}></i></span>
									: <span>{i18next.t("searchLabel")}<i className="fa fa-chevron-down" style={{paddingLeft: "5px"}}></i></span>
								}
							</div>
						</div>
						<div style={{paddingTop: "20px"}}>
							{
								this.state.showSearchForm
								? <TokenInTrashSearchContainer />
								: null
							}
						</div>
					</div>
					<div className="table-holder">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 table_div" style={{paddingLeft: "0px", paddingRight: "0px", height: "100%"}}>
								<div className="row fixed_header_div">

									<div className="col-xs-1 col-sm-1 col-md-1 ccol-lg-1 no-left-right-margin abc-checkbox" style={{ alignSelf: "top"}}>
										<div className="checkbox" style={{borderWidth: "0px", borderStyle: "none", padding: "0px"}}>
					                        <input id="checkbox1" onChange={this.handleSelectAll} checked={this.props.tokenList.selectedAll} type="checkbox" />
					                        <label htmlFor="checkbox1">
					                        </label>
					                    </div>
									</div>

									<div className="col-xs-11 col-sm-11 col-md-5 ccol-lg-2">{i18next.t("userNameSurnameLabel")}</div>
									<div className="col-xs-8 col-sm-4 col-md-6 ccol-lg-3 d-none d-md-block">{i18next.t("uniqueIdLabel")}</div>
									{/*<div className="col-xs-1 col-sm-5 col-md-5 col-lg-2 d-none d-sm-block">{i18next.t("tokenName")}</div>*/}
									<div className="col-xs-1 col-sm-5 col-md-5 ccol-lg-2 d-none d-lg-block">{i18next.t("tokenUri")}</div>
									<div className="col-xs-2 col-sm-4 col-md-4 col-lg-2 d-none d-lg-block">{i18next.t("trashDateTime")}</div>
									<div className="col-xs-2 col-sm-4 col-md-4 col-lg-2 d-none d-lg-block">{i18next.t("trashOutDateTime")}</div>
									{/*<div className="col-xs-1 col-sm-4 col-md-4 col-lg-1 d-none d-lg-block">{i18next.t("tokenStatus")}</div>*/}
									{/*<div className="col-xs-3 col-sm-2 col-md-3 col-lg-1 d-none d-lg-block" style={{paddingLeft: "5px", paddingRight: "5px"}}>{i18next.t("specifications")}</div>*/}
								</div>
								{
									this.props.tokenList.list !== undefined && this.props.tokenList.list.length > 0
									? <div className="fixed_div">
										{
											this.props.tokenList.list.map((token, index) => {
												var activeClass = ""
												if (index % 2) {
													activeClass = "row odd"
												} else {
													activeClass = "row even"
												}
												return (<div key={index} className={token.vtoken.status !== "ACTIVE" ? "row blocked" : activeClass} style={{marginTop: "5px"}}>
															<div className="col-xs-1 col-sm-1 col-md-1 ccol-lg-1 no-left-right-margin abc-checkbox" style={{textAlign: "center", paddingRight: "0px", margin: "auto"}}>
							                            		<div className="checkbox">
											                        <input id={index} checked={this.containElement(index)} onChange={this.handleCheck.bind(this, index)} type="checkbox" />
											                        <label htmlFor={index}>
											                        </label>
											                    </div>
												    		</div>

															<div className="col-xs-11 col-sm-11 col-md-5 ccol-lg-2 row">
																<div className="ccol-11" onClick={this.getSelectedToken.bind(this, index)} style={{paddingLeft: "0px", paddingRight: "0px", margin: "auto"}}>{token.vtoken.owner.commonName}</div><i className="fa fa-copy ccol-1 cursor-pointer" id={"commonName" + index} onClick={this.copyToClipboard.bind(this, token.vtoken.owner.commonName, "commonName" + index)} style={{margin: "auto", paddingLeft: "0px", paddingRight: "0px"}}></i>
															</div>

															<div className="col-xs-8 col-sm-4 col-md-6 ccol-lg-3 row d-none d-md-block">
																<div className="ccol-12 row">
																	<div className="ccol-11" style={{wordBreak: "break-all", paddingLeft: "0px", paddingRight: "0px", margin: "auto"}} onClick={this.getSelectedToken.bind(this, index)}>{token.vtoken.owner.id}</div><i className="fa fa-copy ccol-1 cursor-pointer" id={"idName" + index} onClick={this.copyToClipboard.bind(this, token.vtoken.owner.id, "idName" + index)} style={{margin: "auto", paddingLeft: "0px", paddingRight: "0px"}}></i>
																</div>
															</div>
												    		{/*<div className="col-xs-1 col-sm-5 col-md-5 col-lg-2 d-none d-sm-block cursor-pointer" onClick={this.getSelectedToken.bind(this, index)} style={{textAlign: "center", margin: "auto"}}>
												    			<div>{token.title}</div>
												    		</div>*/}
												    		<div className="col-xs-1 col-sm-5 col-md-5 ccol-lg-2 d-none d-lg-block row">
												    			<div className="ccol-12 row">
																	<div className="ccol-11" style={{paddingLeft: "0px", paddingRight: "0px", margin: "auto"}} onClick={this.getSelectedToken.bind(this, index)}>{token.vtoken.uri}</div><div className="ccol-1"><i className="fa fa-copy cursor-pointer" id={"uriName" + index} onClick={this.copyToClipboard.bind(this, token.vtoken.uri, "uriName" + index)} style={{margin: "auto", paddingLeft: "0px", paddingRight: "0px"}}></i></div>
												    			</div>
												    		</div>
												    		<div className="col-xs-2 col-sm-4 col-md-4 col-lg-2 d-none d-lg-block" onClick={this.getSelectedToken.bind(this, index)} style={{textAlign: "center", margin: "auto"}}>{this.props.actions.formatDate(token.trashDateTime)}</div>
												    		<div className="col-xs-2 col-sm-4 col-md-4 col-lg-2 d-none d-lg-block" onClick={this.getSelectedToken.bind(this, index)} style={{textAlign: "center", margin: "auto"}}>{this.props.actions.formatDate(token.trashOutDateTime)}</div>
												    		{/*<div className="col-xs-1 col-sm-4 col-md-4 col-lg-1 d-none d-lg-block cursor-pointer" onClick={this.getSelectedToken.bind(this, index)} style={{textAlign: "center", margin: "auto"}}>{i18next.t(token.vtoken.status)}</div>
												    		<div className="col-xs-3 col-sm-2 col-md-3 col-lg-1 d-none d-lg-block" style={{textAlign: "center", margin: "auto"}}>
												    			{
												    				token.vtoken.confidants.length > 0
												    				? <i className="fa fa-user" style={{cursor: "pointer"}} onClick={this.handleShowConfidants.bind(this, index)}></i>
												    				: null
												    			}
												    		</div>*/}
												</div>)
											})
										}
				            	</div>
				            	: null
								}
							</div>
						</div>
				</div>
				{
					this.numPages() === 0
					? null
					: <div className={useIEStyle}>
						<div className="row">
							<div className="btn-group dropup" style={{minWidth: "5rem"}}>
								<button type="button" onClick={this.handleShowCountForm} className="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showItemsCountForm}>
									{
										this.props.page.size
									}
								</button>
								<div className={itemsCountFormOpen} style={{minWidth: "5rem"}}>
									{
										this.state.elementsCountArray.map((value, index) => {
											return (<span key={index} className="dropdown-item" style={{cursor: "pointer"}} onClick={this.handleCountChanged.bind(this, value)}>{value}</span>)
										})
									}
								</div>
							</div>

							<div className="row" style={{width: "255px", textAlign: "center"}}>
								<div className={this.props.page.number === 0 ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} onClick={this.firstPage}>
									<img className="img" style={{marginTop: "5px"}} src={first} width="20" height="26" alt={first}/>
								</div>
								<div className={this.props.page.number === 0 ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingTop: "5px"}} onClick={this.prevPage}>
									<i className="fa fa-chevron-left"></i>
								</div>
								<div className="col-5" style={{paddingTop: "5px", textAlign: "center", paddingLeft: "0px", paddingRight: "0px"}}>
									{
										this.props.page.number + 1
									}
									<span style={{paddingTop: "5px"}}> / </span>
									{
										this.numPages()
									}
								</div>
								
								<div className={this.props.page.number === (this.numPages() - 1) ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingTop: "5px", paddingLeft: "0px"}} onClick={this.nextPage}>
									<i className="fa fa-chevron-right"></i>
								</div>
								<div className={this.props.page.number === (this.numPages() - 1) ? "col-1 cursor-default opacity-05" : "col-1 cursor-pointer opacity-1"} style={{paddingLeft: "0px"}} onClick={this.lastPage}>
									<img className="img" style={{marginTop: "5px"}} src={last} width="20" height="26" alt={last}/>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer,
        page: state.trashPaginationReducer,
        tokenList: state.tokenInTrashListReducer,
        search: state.tokenInTrashSearchReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    setActiveTab, changeSearchInTrashState, setSelectedAllTrash,
	    restoreFromTrash, formatDate, getTokenInTrashList, setTokenInTrashList,
	    setALLPaginationParamsForTrash, pageUrl, setPageNumberTrash,
		setPageSizeTrash, setTotalElementsTrash, setTotalPagesTrash,
		setSelectedToken, setTokenEnquire
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TrashTab);