import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Header from '../components/Header';

class HeaderContainer extends Component {

	componentDidMount() {

	}

	render() {
		return (
			<Header />
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);