import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

class ConfidantsPopup extends Component {

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  render() {
      return (
          <div className='popup'>
              <div className={"popup_inner_token_confidants"}>
                  <div className="col-12" style={{marginTop: "30px"}}>
                      <h4 style={{paddingLeft: "15px"}}>{i18next.t("confidants")}</h4>
                      <div>
                          <div className="col-12 table_div" style={{paddingLeft: "0px", paddingRight: "0px", height: "100%"}}>
                            <div className="row">
                              <div className="col-2" style={{paddingRight: "0px"}}>№ </div>
                              <div className="col-10" style={{marginLeft: "0px", paddingLeft: "0px"}}>{i18next.t("commonNameLabel")}</div>
                            </div>
                            <div className="fixed_div_confidants">
                                {
                                  this.props.confidants.map((confidant, index) => {
                                    return (<div key={index} className="row" style={{marginTop: "5px"}}>
                                      <div className="col-2" style={{paddingRight: "0px"}}>{index + 1}&nbsp;</div>
                                      <div className="col-10" style={{marginLeft: "0px", paddingLeft: "0px"}}>{confidant.commonName} [{confidant.id}]</div>
                                    </div>)
                                  })
                                }
                            </div>
                          </div>
                      </div>
                      <div style={{float: "right", marginRight: "15px", paddingTop: "10px"}}>
                          <button className="btn btn-info" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.props.close}>{i18next.t("okLabel")}</button>
                      </div>
                  </div>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        login: state.loginReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfidantsPopup);