import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import {  } from '../actions/localStates'
import { formatDate } from '../actions/helpers'

class CertificateInfoPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {

      }
  }

  componentDidMount() {
      var element = document.getElementById("closeButton");
      element.focus();
      document.addEventListener('keyup', this.handlePinKeyUp);
  }

  componentWillUnmount() {
      document.removeEventListener('keyup', this.handlePinKeyUp);
  }

  handlePinKeyUp(e) {
      if (e.keyCode === 13) {
          document.getElementById("closeButton").click();
          // document.activeElement.blur();
      }
  }

  _renderCertInfo() {
    let certInfo = this.props.certInfo.subject
    let crtInfo = [];
    for (var i in certInfo) {
      crtInfo.push(
                <div key={i} className="input-group">
                    <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t(i)}</label>
                      <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                        {certInfo[i]}
                      </div>
                </div>
              )
        }
        return crtInfo;
  }

  _renderIssuerInfo() {
    let issuerInfo = this.props.certInfo.issuer
    let isrInfo = [];
    for (var i in issuerInfo) {
      isrInfo.push(
                <div key={i} className="input-group">
                    <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t(i)}</label>
                      <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                        {issuerInfo[i]}
                      </div>
                </div>
              )
        }
        return isrInfo;
  }

  _renderPublicKeyInfo() {
    let publicKeyInfo = this.props.certInfo.subjectPublicKeyInfo
    let pkInfo = [];
    for (var i in publicKeyInfo) {
      pkInfo.push(
                <div key={i} className="input-group">
                    <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t(i)}</label>
                      <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                        {publicKeyInfo[i]}
                      </div>
                </div>
              )
        }
        return pkInfo;
  }

  render() {
      return (
          <div className='popup'>
              <div className="popup_inner_cert_info">
              {
                  this.props.certInfo ?
                          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 overflow-y" style={{marginTop: "30px"}}>
                            <h4>{this.props.certInfo.issuer ? i18next.t("certificateInfoLabel") : i18next.t("certificateRequestInfoLabel")}</h4>
                              <div className="card">
                                <div className="card-body left-padding-5">
                                {
                                  this.props.certInfo.serialNumber
                                  ? <div className="input-group">
                                      <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("serialNumber")}</label>
                                        <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                          {this.props.certInfo.serialNumber}
                                        </div>
                                    </div>
                                  : null
                                }
                                {
                                  this.props.certInfo.notBefore
                                  ? <div className="input-group">
                                      <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("notBeforeLabel")}</label>
                                        <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                          {this.props.actions.formatDate(this.props.certInfo.notBefore)}
                                        </div>
                                    </div>
                                  : null
                                }
                                {
                                  this.props.certInfo.notAfter
                                  ? <div className="input-group">
                                      <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("notAfterLabel")}</label>
                                        <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                          {this.props.actions.formatDate(this.props.certInfo.notAfter)}
                                        </div>
                                    </div>
                                  : null
                                }
                                {
                                  this.props.certInfo.issuer
                                  ?  <div className="input-group">
                                      <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("isQualified")}</label>
                                        <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                            {
                                              this.props.certInfo.extensions.isQualified === true ? i18next.t("yesUCase") : i18next.t("noUCase")
                                            }
                                        </div>
                                    </div>
                                  : null

                                }
                                {
                                  this.props.certInfo.issuer
                                  ? <div className="input-group">
                                      <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("isPrivateKeyOnQSCD")}</label>
                                        <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                          {this.props.certInfo.extensions
                                            ? this.props.certInfo.extensions.isPrivateKeyOnQSCD === true ? i18next.t("yesUCase") : i18next.t("noUCase")
                                            : null
                                          }
                                        </div>
                                    </div>
                                  : null
                                }
                                {
                                  this.props.certInfo.issuer
                                  ? <div className="input-group">
                                      <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("qscdTypeName")}</label>
                                        <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                          {this.props.certInfo.extensions
                                            ? this.props.certInfo.extensions.qscdTypeName === null ? i18next.t("notDefined") : this.props.certInfo.extensions.qscdTypeName
                                            : null
                                          }
                                        </div>
                                    </div>
                                  : null
                                }
                                <div className="input-group">
                                  <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("appointment")}</label>
                                    <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                      {this.props.certInfo.extensions
                                        ? this.props.certInfo.extensions.keyUsage[0] === "DIGITAL_SIGNATURE" ? i18next.t("DIGITAL_SIGNATURE") : i18next.t("KEY_AGREEMENT")
                                        : null
                                      }
                                    </div>
                                </div>
                              </div>
                            </div>
                            &nbsp;
                        <div className="card">
                        <div className="card-header">
                          {i18next.t("ownerInfoTitle")}
                        </div>
                        <div className="card-body left-padding-5">
                            {
                              this._renderCertInfo()
                            }
                          </div>
                        </div>
                        &nbsp;
                        {
                          this.props.certInfo.issuer
                          ? <div className="card">
                              <div className="card-header">
                                {i18next.t("issuerInfoTitle")}
                              </div>
                                <div className="card-body left-padding-5">
                                  {
                                    this._renderIssuerInfo()
                                  }
                                </div>
                              </div>
                          : null
                        }
                        
                        &nbsp;
                        <div className="card">
                        <div className="card-header">
                          {i18next.t("publicKeyInfoBodyListTitle")}
                        </div>
                        <div className="card-body left-padding-5">
                            {
                              this._renderPublicKeyInfo()
                            }
                        </div>
                        </div>
                        &nbsp;
                    </div>
                    : null
                  }
                  <div style={{float: "right", marginRight: "15px", paddingTop: "10px", overFlow: "hidden"}}>
                      <button id="closeButton" className="btn btn-outline-info" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.props.closePopup}>{i18next.t("closeLabel")}</button>
                  </div>
              </div>
          </div>
      );
  }
}

function mapStateToProps(state) {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      formatDate
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificateInfoPopup);